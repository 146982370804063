import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
const base_url = process.env.NODE_ENV === "production" ? `${process.env.REACT_APP_API_PROD}` : `${process.env.REACT_APP_API_DEV}`

const initialState = {
  currentWebsiteId: null,
  websiteInfos: null,
  isLoading: false,
  }

export const displayGuestPage = createSlice({
    name: 'websites',
    initialState,
    reducers: {
      setCurrentWebsiteId: (state, action) => {
        state.currentWebsiteId = action.payload;
      },
      setIsLoading: (state, action)=>{
        state.isLoading = action.payload;
      },
      setWebsitesInitialState: (state) => initialState,
    },
    extraReducers(builder) {
      builder.addCase(getWebsiteInfos.fulfilled, (state, action)=>{
        state.websiteInfos = action.payload;
      });
      builder.addCase(uploadImage.fulfilled, (state, action) => {
        state.agendaImage = action.payload;
    });
      builder.addCase(getWebsiteInfosByOrganizerEmail.fulfilled, (state, action) => {
        state.websiteInfos = action.payload;
      })
    }
})

export const { setIsLoading, displayGuestForm, setCurrentWebsiteId, setWebsitesInitialState } = displayGuestPage.actions


export default displayGuestPage.reducer;

export const updateWebsite = createAsyncThunk(
    "website/updateWebsite",
    async (body) => {
      try {
        const response = await axios.put(`${base_url}/api/websites`, body);
        return response.data;
      } catch (error) {
        return false;
      }
    }
  );

  export const getWebsiteInfos = createAsyncThunk(
    "website/getWebsiteInfos",
    async (currentWebsiteId) => {
      try {
        const response = await axios.get(`${base_url}/api/websites/${currentWebsiteId}`);
        return response.data;
      } catch (error) {
        return false;
      }
    }
  )

export const getWebsiteInfosByOrganizerEmail = createAsyncThunk(
  "website/getWebsiteInfosByOrganizerEmail",
  async (organizerEmail) => {
    try {
      const response = await axios.get(`${base_url}/api/websites/byOrganizerEmail/${organizerEmail}`);
      return response.data.websiteInfo;
    } catch (error) {
      return false;
    }
  }
)

export const updateColorsWebsite = createAsyncThunk(
  "website/updateColorsWebsite",
  async (color) => {
    try {
      //console.log("color,", color)
      const res = await axios.put(`${base_url}/api/websites/colors`, color);
      console.log("res", res);
      return res.data;
    } catch (error) {
     return false 
    }
  }
)
  export const updateFontTitleWebsite = createAsyncThunk(
    "website/updateFontTitleWebsite",
    async (body) => {
      try {
        const res = await axios.put(`${base_url}/api/websites/font`, body);
        return res.data;
      } catch (error) {
        return false
      }
    }
  )

export const updateDefaultColor = createAsyncThunk (
  "websites/updateDefaultColor",
  async (websiteId) => {
    try {
      const res = await axios.put(`${base_url}/api/websites/defaultColor/${websiteId}`);
      return res.data;
    } catch (error) {
      return false
    }
  } 
)
export const updateTitleColorWebsite = createAsyncThunk (
  "websites/updateTitleColorWebsite",
  async (body) => {
    try {
      const res = await axios.put(`${base_url}/api/websites/titleColor`, body);
      return res.data;
    } catch (error) {
      return false;
    }
  }
)

export const uploadImage = createAsyncThunk (
  "websites/uploadImage",
  async (body) => {
    try {
      const {data, token, websiteId} = body;
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        "websiteId": websiteId
        }
      const url =`${base_url}/upload`;
      const res = await axios.post(url, data, {
        headers
      });
      console.log(res.data)
      return res.data;
    } catch (error) {
      console.log(error.response.status)
      return error.response.status
    }
  }
)


export const updateWebsiteAgendaImage = createAsyncThunk(
  "websites/updateWebsiteAgendaImage",
  async (body) => {
    try { 
      const result = await axios.put(`${base_url}/api/websites/agendaImage`, body);
      //console.log("result", result);
      return result.data;
    } catch (error) {
      console.log(error)
    }
  }
)

export const createOneWebsite = createAsyncThunk(
  "websites/createOneWebsite",
  async (organizerId) => {
    try {
      const result = await axios.post(`${base_url}/api/websites`, {organizerId: organizerId});
      return result.data;
    } catch (error) {
      console.log(error)
    }
  }
)

