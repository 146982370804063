import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import LocationPin from "./LocationPin.js";
import PageTitle from "../../PageTitle/PageTitle";
import { getAllPlaces } from "../../../reducer/places.js";
import AddPlace from "./AddPlace.js";
import { useDispatch, useSelector } from "react-redux";
import {Box, Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AlertMessage from '../../AlertMessage/AlertMessage.js';
import DeletePlace from './DeletePlace.js';
import DeleteIcon from '@mui/icons-material/Delete';
import Header from '../Header/Header.js';

const MapsPage = () => {
  const dispatch = useDispatch();
  const places = useSelector((state) => state.places);
  const [displayAddPlace, setDisplayAddPlace] = useState(false);
  const [displayDeletePlace, setDisplayDeletePlace] = useState(false);
  const websites = useSelector((state) => state.websites);
  const token = useSelector((state) => state.users.token);
  const [alert, setAlert] = useState({
    display: false,
    message: null,
    type: null,
  });
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  //TODO useeffect qui va chercher les lieux de chaque évenements
  useEffect(() => {
    const fetchAllPlaces = async () => {
      const body = {
        websiteId: websites.currentWebsiteId,
        token,
      }
      await dispatch(getAllPlaces(body))
    };
    if (token !== null) {
    fetchAllPlaces();
    }
  }, [displayDeletePlace]);

  const handleOnClickAddPlace = () => {
    //TODO scroll down
    //https://stackoverflow.com/questions/11715646/scroll-automatically-to-the-bottom-of-the-page
    // window.scrollTo(0, 500);
    setDisplayAddPlace(true);
    setDisplayDeletePlace(false);
  }
  
  return (
    <Box sx={!isMobile ? {} : { pb: 8 }}>
      <Header />
      <PageTitle name="Infos Pratiques" />
      {alert.display && (
        <AlertMessage setAlert={setAlert} alert={alert} />
      )}
      {places && places.length >= 0 &&
      <Box sx={!isMobile ? { flexGrow: 1, pl: 4, pr: 4, pb: 2 } : { flexGrow: 1, pl: 1, pr: 1, pb: 2 }}>
          <div className="google-map" style={{ height: "70vh" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_API_KEY_MAPS_JS }}
              defaultCenter={{ lat: places.length == 0 ? 48.87372135445968 : Number(places[0].lat), lng: places.length == 0 ? 2.419590527175259 : Number(places[0].lng) }} 
              defaultZoom={11}
            >
              {places.length > 0 &&
                places.map((place, index) => (
                  <LocationPin key={index} lat={place.lat} lng={place.lng} place={place}/>
                ))}
            </GoogleMapReact>
          </div>
      </Box>
      }
        <Button
          startIcon={<AddIcon />}
          onClick={(handleOnClickAddPlace)}
          variant="contained"
          size={!isMobile ? 'medium' : 'small'}
          sx={!isMobile ? { mt: 2, ml: 4, mb: 4 } : {width: '95%', pl:0, pr:0, pt: 1, pb: 1, m:1}}
        >
          Ajout d'une adresse de logement ou de tourisme
        </Button>
      <Button
        startIcon={<DeleteIcon />}
        onClick={() => {setDisplayAddPlace(false); setDisplayDeletePlace(true)}}
        variant="contained"
        sx={!isMobile ?  { mt: 2, ml:2, mb: 4}: {width: '95%', pl:0, pr:0, m:1}}
      >
        Supprimer une adresse
      </Button>
      {displayAddPlace && <AddPlace setDisplayAddPlace={setDisplayAddPlace} setAlert={setAlert}/>}
      {displayDeletePlace && <DeletePlace setOpen={setDisplayDeletePlace } setAlert={setAlert} />}
    </Box>
  );
};

export default MapsPage;
