import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import { Modal, Box, Stack, Typography, Button } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import { useDispatch, useSelector } from "react-redux";
import {
  addGuestsFromCsvList,
  getAllGuests
} from "../../../reducer/guests.js";

const DisplayCsvUploadModal = ({ isOpen, setIsOpen, setAlert }) => {
  const dispatch = useDispatch();
    const organizers = useSelector((state) => state.organizers);
    const websites = useSelector((state) => state.websites);
    const page = useSelector((state) => state.guests.page);
    const limit = useSelector((state) => state.guests.rowsPerPage);
    const token = useSelector((state) => state.users.token);
    const [width, setWidth] = useState(window.innerWidth);
    const isMobile = width <= 767;
    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    };
  
    useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
    }, []);
  function checkFileExtension(fileName) {
    const extension = fileName.split('.').pop();
    return extension;
  }

    const handleImportCsvFile = async (event) => {
      const maxFileSize = 2 * 1024 * 1024; // 2 MB
      if (event.target.files[0].size > maxFileSize) {
        setAlert({
          display: true,
          message: "la taille du fichier est trop grande",
          type: "error",
        });
      } else if (checkFileExtension(event.target.files[0].name)!=="csv") {
        setAlert({
          display: true,
          message: "L'extension du fichier n'est pas valide",
          type: "error",
        });
      }else{
    //on parse le fichier csv
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        let fiteredRes = results.data.map((result) => {
          return({
          Nom: result.Nom || result.nom || result.noms || result.Noms || result.firstname,
          Prénom: result.prénom || result.Prénom || result.prénoms || result.Prénoms || result.lastname,
          Email: result.Email || result.email || result.mail || result.mails || result.emails || result["e-mail"] || result["E-mail"],
        })});

        let trimmedFilteredRes = fiteredRes.map(obj => {
          let trimmedObj = {};
          Object.keys(obj).forEach(key => {
            if (obj[key]===undefined){
              setAlert({
                display: true,
                message: 'le fichier ne contient pas les bons noms de colonnes, veuillez les nommer Nom, Prénom et Email',
                type: "error",
              });
            }
            trimmedObj[key] = obj[key].trim();
          });
          return trimmedObj;
        });
        
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isValidEmail = (email) => {
          return emailRegex.test(email);
        };
        
        const isAllEmailValid = trimmedFilteredRes.find((item) => !isValidEmail(item.Email));
        console.log('isAllEmailValid', isAllEmailValid)
        if (isAllEmailValid){
          setAlert({
            display: true,
            message: 'le fichier contient un ou plusieurs email non valid',
            type: "error",
          });
        } else {
        //on selectionne uniquement les colonnes qui nous interesse pour creer multiple rows dans guest table
        if (trimmedFilteredRes !== null && trimmedFilteredRes.length > 0) {
          const body= {
            organizerId: organizers.currentOrganizerId,
            websiteId: websites.currentWebsiteId,
            guestArray: trimmedFilteredRes,
            token
          }
          const res = await dispatch(addGuestsFromCsvList(body));
          if (res.payload.status === 200) {
              //pour provoquer le rechargement de la page et récupérer les Guests;
              setAlert({
                  display: true,
                  message: res.payload.message,
                  type: "success",
                });
                setIsOpen(false);
              const param = {
                  organizerId: organizers.currentOrganizerId,
                  websiteId: websites.currentWebsiteId,
                  page: page + 1,
                  limit,
                  token
              };
              dispatch(getAllGuests(param))
          } 
          if (res.payload.status === 500) {
            //pour provoquer le rechargement de la page et récupérer les Guests;
            setAlert({
              display: true,
              message: res.payload.message,
              type: "error",
            });
            setIsOpen(false);
          } 
          if(!res){
            setIsOpen(false);
            setAlert({
              display: true,
              message: "Les données du fichier csv ne peuvent être importées",
              type: "error",
            });
          }
        }
      }
      },
    });
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const style = !isMobile ? {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  } : {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };
  return (
    <Modal
      open={isOpen}
      onClose={(e, reason) => {
        if (reason !== "backdropClick") {
          closeModal();
        }
      }}
    >
      <Box sx={style}>
        <Typography>
          <i>Afin d'éviter les doublons, si des invités parmis la liste csv ont déjà été ajoutés, ils ne seront pas importés</i><br /><br />
          <b>Pour ajouter des invités depuis un fichier csv, le fichier doit
            contenir au moins 3 colonnes nommées "Nom", "Prénom" et "Email".</b>
        </Typography>

        <Stack
          sx={{ marginTop: "2rem", justifyContent: "flex-end" }}
          direction="row"
          spacing={2}
        >
          <Button
            startIcon={<UploadIcon />}
            sx={{ ml: 2, mr: 2, width: "fit-content", height: "2.5rem" }}
            variant="contained"
            component="label"
          >
            Importer liste CSV
            <input
              type="file"
              hidden
              name="file"
              accept=".csv"
              onChange={handleImportCsvFile}
            />
          </Button>
          <Button variant="outlined" onClick={closeModal}>
            Annuler
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
export default DisplayCsvUploadModal;
