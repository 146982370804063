import React, { useState, useEffect } from "react";
import { Container, Stack, Typography, Button } from "@mui/material";
import "./HomePageOrganizer.scss";
import RegisterOrganizerForm from "./RegisterOrganizerForm.js";
import LoginOrganizerForm from "./LoginOrganizerForm.js";
import Footer from "../pagesOrganizer/Footer/Footer";

const HomePageOrganizer = () => {
  const [displayRegisterOrganizerForm, setDisplayRegisterOrganizerForm] =
    useState(false);
  const [displayLoginOrganizerForm, setDisplayLoginOrganizerForm] =
    useState(false);
  const [alert, setAlert] = useState({
    display: false,
    message: null,
    type: null,
  });
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  return (
    <>
    <Container sx={{ width: "100 %", height: "100vh" }} className="homeOrganizer__img">
      <Stack
        sx={{
          textAlign: "center",
          marginTop: "3rem",
          fontSize: "3rem",
        }}
      >
      <Typography variant="homePage" sx={!isMobile ? {mb: '3rem'} : {}}>
          Vous avez prévu un mariage ou un pacs ?
      </Typography>
     
        {displayRegisterOrganizerForm && (
          <RegisterOrganizerForm
            setIsOpenRegisterForm={setDisplayRegisterOrganizerForm}
            isOpen={displayRegisterOrganizerForm}
            setIsOpenLoginForm={setDisplayLoginOrganizerForm}
            setAlert={setAlert}
            alert={alert}
          />
          
        )}
        {displayLoginOrganizerForm && (
          <LoginOrganizerForm
            setIsOpenLoginForm={setDisplayLoginOrganizerForm}
            isOpen={displayLoginOrganizerForm}
            setIsOpenRegisterForm={setDisplayRegisterOrganizerForm}
            setAlert={setAlert}
            alert={alert}
          />
        )}
      </Stack>
        <Typography sx={!isMobile ? { marginTop: "25%", marginLeft: '-2rem', fontStyle: "italic" } : { marginTop: 0, marginLeft: '0rem', fontStyle: "italic" }}>
          DDay vous accompagne, pour inventer l'évenement qui vous ressemble ...
        </Typography>
        <Stack
          sx={!isMobile ? { display: "flex", flexDirection: "row", justifyContent: "end", marginTop: "10%" } : { display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginTop: "5%" }}
        >
          <Button
            variant="contained"
            type="submit"
            onClick={() => setDisplayRegisterOrganizerForm(true)}
          >
            S'inscrire
          </Button>
          <Button
            sx={{ marginLeft: "0.5rem" }}
            variant="contained"
            type="submit"
            onClick={() => setDisplayLoginOrganizerForm(true)}
          >
            Se connecter
          </Button>
        </Stack>
    </Container>
        <Footer />
    </>
  );
};
export default HomePageOrganizer;
