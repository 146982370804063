import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const base_url = process.env.NODE_ENV === "production" ? `${process.env.REACT_APP_API_PROD}` : `${process.env.REACT_APP_API_DEV}`


const initialState = {
    messageInfos: null,
}

export const messages = createSlice({
    name: "messages",
    initialState,
    reducers: {
   
    },
    extraReducers(builder) {
        builder.addCase(getAllMessage.fulfilled, (state, action) => {
            state.messageInfos = action.payload;
        });
    },
});

export const {  } = messages.actions;

export const getAllMessage = createAsyncThunk(
    "messages/getAllMessage",
    async (body) => {
        const { websiteId , token} = body;
        try {
            const headers = {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                "websiteid": websiteId
            }
            const result = await axios.get(`${base_url}/api/messages/${websiteId}`, {headers});
            return result.data;
        } catch (error) {
           return false; 
        }
    }
)

export const addOneMessage = createAsyncThunk(
    "messages/addOneMessage",
    async (body) => {
        const {websiteId, token} = body;
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
            "websiteid": websiteId
        }
        try {
            const result = await axios.post(`${base_url}/api/messages/${websiteId}`, body, {headers});
           console.log("result.data", result.data);
           return result.data;
        } catch (error) {
          return false;  
        }
    }
)
export const deleteOneMessage = createAsyncThunk(
    "messages/deleteOneMessage",
    async (body) => {
        const { messageId, token, websiteId} = body;
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
            "websiteid": websiteId
        }
        try {
            const result = await axios.delete(`${base_url}/api/messages/${messageId}`, {headers});
            console.log("result.data", result.data);
            return result.data;
        } catch (error) {
            return false;
        }
    }
)

export default messages.reducer;