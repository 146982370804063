import cityHall from "../../assets/svg/cityHall.svg";
import bell from "../../assets/svg/bell.svg";
import brunch from "../../assets/svg/brunch.svg";
import diner from "../../assets/svg/diner.svg";
import coktail from "../../assets/svg/coktail.svg";
import danse from "../../assets/svg/danse.svg";
import bed from "../../assets/svg/bed.svg";
import visit from "../../assets/svg/visit.svg";

export const eventIcons = {
    cityHall,
    bell,
    brunch,
    diner,
    coktail,
    danse,
}

export const eventIconsInfos = [
    { name: "bell", label: "Céremonie" },
    { name: "brunch", label: "Brunch" },
    { name: "cityHall", label: "Mairie" },
    { name: "coktail", label: "Coktail" },
    { name: "diner", label: "Dîner" },
    { name: "danse", label: "Danse" },
];
export const placeIcons = {
    bed,
    visit,
}
export const placeIconsInfos = [
    { name: "bed", label: "Logement" },
    { name: 'visit', label: "A visiter"}
]