import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const base_url = process.env.NODE_ENV === "production" ? `${process.env.REACT_APP_API_PROD}` : `${process.env.REACT_APP_API_DEV}`

export const organizers = createSlice({
    name: "organizers",
    initialState: {
        role: null,
        currentOrganizerId: null,
        currentOrganizerEmail: null,
        currentOrganizerInfos: null,
    },
    reducers: {
        setCurrentOrganizerEmail: (state, action) => {
            state.currentOrganizerEmail = action.payload;
        }
    },
    extraReducers(builder) {
        builder.addCase(createOrganizerRole.fulfilled, (state, action) => {
            state.role = action.payload;
        });
        builder.addCase(createOneOrganizer.fulfilled, (state, action) => {
            state.organizerId = action.payload.organizerId;
        }); 
        builder.addCase(getCurrentOrganizerId.fulfilled, (state, action) => {
            state.currentOrganizerId = action.payload.organizerId;
            state.currentOrganizerInfos = action.payload;
        });
    },
});

export const { setCurrentOrganizerEmail } = organizers.actions;

export const createOrganizerRole = createAsyncThunk(
    "organizers/createOrganizerRole",
    async (body) => {
        try {
            //console.log("uid", body)
            const response = await axios.post(`${base_url}/role/setOrganizer`, body);
            return response.data;
        } catch (error) {
            return false;
        }
    }
);

export const addOrganizerCustomClaimsIfUserAnyClaims = createAsyncThunk(
    "organizers/addOrganizerCustomClaimsIfUserAnyClaims",
    async (body) => {
        try {
            const response = await axios.post(`${base_url}/role/addOrganizerCustomClaimsIfUserAnyClaims`, body);
           console.log('response', response.data)
           return response.data;
        } catch (error) {
            return false;
        }
    }
)
export const createOneOrganizer = createAsyncThunk(
    "organizers/createOneOrganizer",
    async (body) => {
        try {
            const response = await axios.post(`${base_url}/api/organizers`, body); 
           //console.log('response', response);
           return response.data;
        } catch (error) {
            return false;
        }
    }
)

export const getCurrentOrganizerId = createAsyncThunk(
    "organizers/getCurrentOrganizerId",
    async (organizerEmail) => {
        try {
            const response = await axios.get(`${base_url}/api/organizers/${organizerEmail}`) ;
            //console.log('response', response);
            return response.data.infos;
        } catch (error) {
           return false; 
        }
    }
)




export default organizers.reducer;
