import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './component/App/App.js';
import { Provider } from 'react-redux';
import store from './store/store.js';
import './index.scss';
import { BrowserRouter } from "react-router-dom";

// Import the functions you need from the SDKs you need
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const root = ReactDOM.createRoot(document.getElementById('root'));

// const Authenticated = (props) => {
//   // User not login
//   // redirect to login/registration page

//   // User already login
//   // user is organizer ?
//   // Yes => redirect to oganizer tab
//   // No => redirect to guest tab
//   return <>{props.children}</>
// }

root.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <Authenticated> */}
        <App />
      {/* </Authenticated> */}
    </BrowserRouter>
  </Provider>
);


