import React, { useState, useEffect } from "react";
import PageTitle from "../../PageTitle/PageTitle";
import { useSelector, useDispatch } from "react-redux";
import "./RsvpPage.scss";
import SendIcon from "@mui/icons-material/Send";
import { sendRsvpGuest } from "../../../reducer/guests.js";
import HeaderGuest from "../HeaderGuest/HeaderGuest";
import {
  Grid,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Stack,
  Button,
  Paper,
  Typography,
  FormHelperText
} from "@mui/material";
import { getAuth } from "firebase/auth";
import AlertMessage from '../../AlertMessage/AlertMessage';

//TODO verifier si les valeur ne sont pas null, gestio des messages

const RsvpPageGuest = () => {
  
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const arrayDisplayName= currentUser.displayName.split('+');
  const email = currentUser.email;
  const dispatch = useDispatch();
  const token = useSelector((state) => state.users.token);
  const [willBePresent, setWillBePresent] = useState({ errorText: "", value: null });
  const [partnerWillBePresent, setPartnerWillBePresent]= useState({ errorText: "", value: null });
  const [partnerFirstName, setPartnerFirstName] = useState({ errorText: "", value: null });
  const [partnerLastName, setPartnerLastName] = useState({ errorText: "", value: null });
  const [numberOfChildren, setNumberOfChildren] = useState({ errorText: "", value: 0 });
  const [aboutFoodRemark, setAboutFoodRemark] = useState({ errorText: "", value: null });
  const websites = useSelector((state) => state.websites);
  const [alert, setAlert] = useState({
    display: false,
    message: null,
    type: null,
  });
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);



  const OnSubmitRsvp= async () => {
    if (willBePresent.value === null || willBePresent.value.length === 0) {
      setWillBePresent({ errorText: "cocher le bouton indiquant votre présence ", value: null });
      return;
    }
    
    if (partnerWillBePresent.value === null || partnerWillBePresent.value.length === 0) {
      setPartnerWillBePresent({ errorText: "cocher le bouton indiquant la présence de votre conjoint ", value: null });
      return;
    }
    if ((partnerWillBePresent.value === "Oui" && partnerFirstName.value === null) || (partnerWillBePresent.value === "Oui" &&partnerFirstName.value.length === 0)) {
      setPartnerFirstName({ errorText: "Indiquez le prénom de votre conjoint", value: null });
      return;
    }
    if ((partnerWillBePresent.value === "Oui" && partnerLastName.value === null )||(partnerWillBePresent.value === "Oui" && partnerLastName.value.length === 0)) {
      setPartnerLastName({ errorText: "Indiquez le nom de votre conjoint", value: null });
      return;
    }
    
    const body = {
      willBePresent: willBePresent.value,
      partnerWillBePresent: partnerWillBePresent.value,
      partnerFirstName: partnerFirstName.value,
      partnerLastName: partnerLastName.value,
      numberOfChildren: numberOfChildren.value,
      aboutFoodRemark: aboutFoodRemark.value,
      websiteId: websites.currentWebsiteId,
      email,
      token
    }

    const res = await dispatch(sendRsvpGuest(body))
    if(res.payload.status === 200){
      setAlert({
        display: true,
        message: res.payload.message,
        type: "success",
      })
    }else{
      setAlert({
        display: true,
        message: res.payload.message || 'impossible de transmettre la réponse',
        type: "error",
      })
    }
  }

  return (
    <div>
      <HeaderGuest />
      <PageTitle name="RSVP" />
      <Grid sx={{ display: "flex", flexDirection: "row" }}>
        <Paper
          sx={!isMobile ? { padding: '2rem', ml: 4, mb: 2, display: "flex", flexDirection: "column", width: '90%' } : { padding: '1rem', ml: 2, mb: 6, display: "flex", flexDirection: "column", width: '100%' }}
        >
          {alert.display && (
            <AlertMessage setAlert={setAlert} alert={alert} />
          )}
          <FormControl sx={{ mb: 4 }}>
            <FormLabel id="demo-row-radio-buttons-group-label">
              <Stack direction="row"><Typography sx={{ fontWeight: 'bold'}}> {arrayDisplayName[0].trim()} {arrayDisplayName[1].trim()}</Typography><Typography>, je confirme ma présence :</Typography></Stack>
            </FormLabel>
            <RadioGroup
              required
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              onChange={(e) => setWillBePresent({ errorText: "", value: e.target.value})}
            >
              <FormControlLabel value={"Oui"} control={<Radio />} label="oui" />
              <FormControlLabel value={"Non"} control={<Radio />} label="non" />
            </RadioGroup>
            <FormHelperText sx={{ color: "#de9999" }}>
              {willBePresent.errorText}
            </FormHelperText>
          </FormControl>
          <FormControl sx={{ mb: 4 }}>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Je confirme la présence de mon +1:
            </FormLabel>
            <RadioGroup
              row
              required
              aria-labelledby="demo-row-radio-buttons"
              /* name="row-radio-buttons-group" */
              onChange={(e) => setPartnerWillBePresent({ errorText: "", value: e.target.value})}
            >
              <FormControlLabel value={"Oui"} control={<Radio />} label="oui" />
              <FormControlLabel value={"Non"} control={<Radio />} label="non" />
            </RadioGroup>
            <FormHelperText sx={{ color: "#de9999" }}>
              {partnerWillBePresent.errorText}
            </FormHelperText>
            {partnerWillBePresent.value==="Oui" ? (
              <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
                <TextField
                  label="Prénom"
                  variant="standard"
                  required
                  sx={{ width: "30%", pb: 1 }}
                  helperText={partnerFirstName.errorText}
                  onChange={(e) => setPartnerFirstName({ errorText: "", value: e.target.value})}
                  error={partnerFirstName.errorText.length === 0 ? false : true}
                  inputProps={{ maxLength: 30 }}
                />
                <TextField
                  label="Nom"
                  variant="standard"
                  required
                  sx={{ width: "40%", pb: 1 }}
                  helperText={partnerLastName.errorText}
                  onChange={(e) => setPartnerLastName({ errorText: "", value: e.target.value})}
                  error={partnerLastName.errorText.length === 0 ? false : true}
                  inputProps={{ maxLength: 30 }}
                />
              </Stack>
            ): <></>}
            
          </FormControl>
          <FormControl sx={{ mb: 4 }}>
            <FormLabel sx={{ mb: 1 }} id="demo-row-radio-buttons-group-label">
              J'indique le nombre d'enfants qui m'accompagnent :
            </FormLabel>
            <TextField
              sx={{ width: "20%" }}
              id="standard-number"
              label="Nombre d'enfants"
              type="number"
              defaultValue="0"
              InputProps={{
                inputProps: { 
                    max: 20, min: 0 
                }
            }}
              variant="standard"
              helperText={numberOfChildren.errorText}
              onChange={(e) => setNumberOfChildren({ errorText: "", value: e.target.value})}
              error={numberOfChildren.errorText.length === 0 ? false : true}
            />
          </FormControl>
          <FormControl sx={{ mb: 4 }}>
            <FormLabel sx={{ mb: 1 }} id="demo-row-radio-buttons-group-label">
              J'indique mes remarques (régime particulier, allergies, autres) :
            </FormLabel>
            <TextField
              id="standard-multiline-static"
              label="Mes remarques"
              multiline
              rows={3}
              variant="standard"
              helperText={aboutFoodRemark.errorText}
              onChange={(e) => setAboutFoodRemark({ errorText: "", value: e.target.value})}
              error={aboutFoodRemark.errorText.length === 0 ? false : true}
            />
          </FormControl>
            <Button
              sx={!isMobile ? {width: '30%'}: {width: '100%'}}
              startIcon={<SendIcon />}
              variant="contained"
              onClick={OnSubmitRsvp}
              // size={isMobile ? "small" : "medium"}
            >
              Envoyer ma réponse
            </Button>
        </Paper>
      </Grid>
    </div>
  );
};
export default RsvpPageGuest;
