import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import PageTitle from "../../PageTitle/PageTitle.js";
import { Paper, Box, Button, Stack, TextField, IconButton } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getAllMessage, addOneMessage, deleteOneMessage } from "../../../reducer/messages";
import moment from "moment";
import { momentLocaleConfig } from "../../../utils/momentLocalConfig.js";
import DeleteIcon from "@mui/icons-material/Delete";

const LivreOr = () => {
  moment.updateLocale('fr', momentLocaleConfig)
  const websites = useSelector((state) => state.websites);
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.messages);
  const token = useSelector((state) => state.users.token);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    const fetchAllMessage = async () => {
      const body = {
        websiteId: websites.currentWebsiteId,
        token
      }
      await dispatch(getAllMessage(body));
    };
    if (token !== null) {
    fetchAllMessage();
    }
  }, []);


  const handleDeleteMessage = async (e) => {
    const body = {
      messageId: e.currentTarget.id,
      token,
      websiteId: websites.currentWebsiteId
    }
    const res = await dispatch(deleteOneMessage(body));
    if(res.payload.status===200){
      const body = {
        websiteId: websites.currentWebsiteId,
        token
      }
      await dispatch(getAllMessage(body));
    }
  }

  return (
    <div>
      <Header />
      <PageTitle name="Livre d'Or" />
      {messages.messageInfos !== null &&
        messages.messageInfos.map((message) => {
          return (
            <Box
              key={message.messageId}
              sx={!isMobile ? { pl: "2rem", pr: "2rem", pt: "0.5rem" } : { pl: "2rem", pr: "2rem", pt: "0.5rem", pb: 4 }}
            >
              <Paper sx={{ padding: "2rem" }}>
                <Stack direction="row" justifyContent="space-between">
                  <Stack>
                    <Stack direction="row" spacing={1}>
                      <strong>{message.signature}</strong>
                      <i> - {moment(message.created_at).format("LLL")}</i>
                  </Stack>
                    <p>{message.content}</p>
                  </Stack>
                  <Stack>
                      <IconButton onClick={handleDeleteMessage} id={message.messageId}>
                        <DeleteIcon />
                      </IconButton>
                  </Stack>
              </Stack>
              </Paper>
            </Box>
          );
        })}
       {messages.messageInfos&&messages.messageInfos.length===0&&<Box sx={{p:2}}>Ici vous recevrez les messages envoyés par vos invités.</Box>}
    </div>
  );
};
export default LivreOr;
