import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Tooltip,
  IconButton,
  Stack,
  Toolbar,
  Input,
  InputLabel,
  InputAdornment
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import { alpha } from "@mui/material/styles";
import AlertDeleteConfirm from './AlertDeleteConfirm.js';
import MailSendModal from './MailSendModal';
import { isFirstEmailSend } from "../../../reducer/guests.js";
import AlertMessage from "../../AlertMessage/AlertMessage.js";
import { getAllGuests } from '../../../reducer/guests';
import SearchIcon from "@mui/icons-material/Search";

const GuestTableToolbar = (props) => {
  const { searchQuery, setSearchQuery, numSelected, selectedIdGuests, page, rowsPerPage, setAlert } = props;
  const [displayMailModal, setDisplayMailModal] = useState(false);
  const [emailsToContact, setEmailsToContact] = useState([]);
  const allGuestInfos = useSelector((state) => state.guests.allGuests);
  const [alertDeleteConfirmation, setAlertDeleteConfirmation] = useState("");
  const dispatch = useDispatch();
  const organizers = useSelector((state) => state.organizers);
  const websites = useSelector((state) => state.websites);
  const guests = useSelector((state) => state.guests);
  const token = useSelector((state) => state.users.token);
  
  const handleOnClickDelete = async() => {
    setAlertDeleteConfirmation(true);
  }
  
  const handleOnClickSendBtn = async (e) => {
    const param = {
      organizerId: organizers.currentOrganizerId,
      websiteId: websites.currentWebsiteId,
      page: guests.page + 1,
      limit: guests.rowsPerPage,
      token
    };
    const res = await dispatch(getAllGuests(param));
    if(res.payload) {

      const guestsToContact = res.payload.result.filter((guest) => selectedIdGuests.includes(guest.guestId));
      const emailsToContact = guestsToContact.map((guest) => guest.email);
      setEmailsToContact(emailsToContact);
      console.log('emailsToContact', emailsToContact, allGuestInfos, selectedIdGuests);
      //vérification si dans les guests selectionnés certains ont déjà reçu l'invitation
      const isGuestsSelectedFirstMailAllreadySend = await dispatch(isFirstEmailSend({ organizerId: organizers.currentOrganizerId, websiteId: websites.currentWebsiteId, emailsToContact:emailsToContact}));
      console.log("hello", isGuestsSelectedFirstMailAllreadySend.payload);
      
      if (isGuestsSelectedFirstMailAllreadySend.payload.status=== 200){
        setDisplayMailModal(true);
      }else{
        setAlert({
          display: true,
          message: isGuestsSelectedFirstMailAllreadySend.payload.message,
          type: "error",
        });
        console.log(isGuestsSelectedFirstMailAllreadySend.payload.message);
      }
    }
  };


  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {alertDeleteConfirmation && <AlertDeleteConfirm setAlert={setAlert} open={alertDeleteConfirmation}  setOpen={setAlertDeleteConfirmation} selectedIdGuests={selectedIdGuests} page={page} rowsPerPage={rowsPerPage}/>}
      {alert.display && (
        <AlertMessage setAlert={setAlert} alert={alert} />
      )}
      {displayMailModal && (
        <MailSendModal displayMailModal={displayMailModal} setDisplayMailModal={setDisplayMailModal} numSelected={numSelected} emailsToContact={emailsToContact} page={page} rowsPerPage={rowsPerPage}/>
      )}
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
        </Typography>
      )}
      <Input
        sx={{mr: '1rem'}}
        id="standard"
        type={'text'}
        onInput={(e) => {
          setSearchQuery(e.target.value);
        }}
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon></SearchIcon>
          </InputAdornment>
        }
      />
      {numSelected > 0 ? (
        <Stack direction="row">
          <Tooltip title="Send">
            <IconButton onClick={handleOnClickSendBtn}>
              <SendIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={handleOnClickDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      ) : (
        <div></div>
      )}
    </Toolbar>
  );
};
export default GuestTableToolbar;

GuestTableToolbar.propTypes = {
  // numSelected: PropTypes.number.isRequired,
};
