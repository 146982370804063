import React from 'react'
import { useSelector } from "react-redux";

const Star = () => {
    const websites = useSelector((state) => state.websites);

return (
    <svg className="title__star" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 1C13.4091 13.6061 15.1642 14 1 14" stroke={websites.websiteInfos.titleColor}  />
        <path d="M27 14C14.3939 13.4091 14 15.1642 14 1" stroke={websites.websiteInfos.titleColor} />
        <path d="M14 27C14.5909 14.3939 12.8358 14 27 14" stroke={websites.websiteInfos.titleColor} />
        <path d="M1 14C13.6061 14.5909 14 12.8358 14 27" stroke={websites.websiteInfos.titleColor} />
    </svg>

)
};
export default Star;

