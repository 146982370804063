import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Grid,
  Link,
  Button,
  TextField,
  Stack,
  Typography
} from "@mui/material";
import { registerWithEmailAndPassword } from "../../services/firebase.js";
import AlertMessage from "../AlertMessage/AlertMessage.js";
import { validateEmail } from '../../utils/guest.js';
import {useDispatch } from 'react-redux';
import { createGuestRole } from '../../reducer/guests';
import { setCurrentWebsiteId, setIsLoading } from '../../reducer/websites';


const RegisterOrganizerForm = ({
  setIsOpenRegisterForm,
  isOpen,
  setIsOpenLoginForm,
  setAlert,
  alert,
  names,
  websiteId
}) => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState({ errorText: "", value: null });
  const [lastName, setLastName] = useState({ errorText: "", value: null });
  const [password, setPassword] = useState({ errorText: "", value: null });
  const [email, setEmail] = useState({ errorText: "", value: null });
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  //Afficher message erreur de firebase via alert

  const closeModal = () => {
    setIsOpenRegisterForm(false);
  };
  const handleRegister = async () => {
    if (firstName.value === null || firstName.value.length === 0) {
      setFirstName({ errorText: "Indiquez votre prénom ", value: null });
      return;
    }
    if (lastName.value === null || lastName.value.length === 0) {
      setLastName({ errorText: "Indiquez votre nom ", value: null });
      return;
    }
    if (email.value === null || email.value.length === 0) {
      setEmail({ errorText: "Indiquez votre mail ", value: null });
      return;
    }
    if (!validateEmail(email.value)) {
      setEmail({ errorText: "Votre email n'est pas valide", value: null });
      return;
    }
    if (password.value === null || password.value.length === 0) {
      setPassword({ errorText: "Indiquez un mot de passe ", value: null });
      return;
    }

    const res = await registerWithEmailAndPassword(
      firstName.value,
      lastName.value,
      email.value,
      password.value
    );
    if (res.status === 200) {
      dispatch(setIsLoading(true));
      closeModal();
      //console.log("res.uid", res.uid);
      const uid = res.uid;
      const ress =  await dispatch(createGuestRole({ uid, websiteId }));
      if(ress.payload.status===200){
        dispatch(setCurrentWebsiteId(websiteId));
      }
    }
    if (res.error) {
      console.log(res.error);
      setAlert({
        display: true,
        message: res.error,
        type: "error",
      });
    }
  };
  const style = !isMobile ? {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  }: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "85%",
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 1
  };
  return (
    <Modal
      open={isOpen}
      onClose={(e, reason) => {
        if (reason !== "backdropClick") {
          closeModal();
        }
      }}
    >
      <Box sx={style}>
      {alert.display && (
        <AlertMessage setAlert={setAlert} alert={alert} />
      )}
        <Stack
          sx={{ fontSize: "2rem", paddingTop: "2rem", paddingBottom: "2rem" }}
        >
          <Typography variant="h2">
            S'incrire sur le site de {names}
          </Typography>
        </Stack>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="Prénom"
                onChange={(e) =>
                  setFirstName({ errorText: "", value: e.target.value })
                }
                autoFocus
                error={firstName.errorText.length === 0 ? false : true}
                helperText={firstName.errorText}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Nom"
                name="lastName"
                autoComplete="family-name"
                onChange={(e) =>
                  setLastName({ errorText: "", value: e.target.value })
                }
                error={lastName.errorText.length === 0 ? false : true}
                helperText={lastName.errorText}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                onChange={(e) =>
                  setEmail({ errorText: "", value: e.target.value })
                }
                error={email.errorText.length === 0 ? false : true}
                helperText={email.errorText}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="new-password"
                onChange={(e) =>
                  setPassword({ errorText: "", value: e.target.value })
                }
                error={password.errorText.length === 0 ? false : true}
                helperText={password.errorText}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleRegister}
          >
            M'inscrire
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  setIsOpenRegisterForm(false);
                  setIsOpenLoginForm(true);
                }}
              >
                J'ai déjà un compte? Me connecter
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};
export default RegisterOrganizerForm;
