import React, { useState, useRef, useEffect } from "react";
import {
  Typography,
  Stack,
  Modal,
  Box,
  Button,
  Popover,
  TextField,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useSelector, useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { sendSaveTheDateMail, updateIsFirstEmailSend, getAllGuests } from "../../../reducer/guests.js";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";

const MailSendModal = ({
  displayMailModal,
  setDisplayMailModal,
  numSelected,
  emailsToContact,
  rowsPerPage,
  page
}) => {
  
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const guests = useSelector((state) => state.guests);
  const organizers = useSelector((state) => state.organizers);
  const websites = useSelector((state) => state.websites);
  const token = useSelector((state) => state.users.token);
  const [emailMessage, setEmailMessage] = useState(
    `Bonjour à tous! \n \nNous souhaitons vous faire parvenir un « Save The Date » pour notre mariage ! \nL’évènement se déroulera le ${websites.websiteInfos.date}. \nVous avez reçu un mail pour vous connecter sur notre site DDay (vérifiez les spams). Nous vous demanderons alors de confirmer votre présence via le site. Vous y trouverez toutes les informations des festivités. \nEn attendant, portez vous bien, \n \nAu plaisir de vous voir! \n \n${websites.websiteInfos.title}`
  );
  const [eEmailSubject, setEmailSubject] = useState(`Save the Date !`);
  const [displayConfirmationBtn, setDisplayConfirmationBtn] = useState(false);
  const refBtnYes = useRef();
  const auth = getAuth();
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  const base_url = process.env.NODE_ENV === "production" ? `http://dday.dev` : `http://localhost:3000`
  const dynamicLinkDomain = process.env.NODE_ENV === "production" ? `dday.page.link` : `ddaydev.page.link`

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const style = !isMobile ? {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  } : {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "90%",
      height: "90%",
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 1,
      overflow: "scroll",
  };
  
  useEffect(()=>{
  }, [displayMailModal])

  const handleSubmitMailSend = async () => {
    
    //solution 1 mais pas de html possible
    window.open(
      `mailto:?bcc=${emailsToContact}&subject=${eEmailSubject}&body=${encodeURIComponent(
        emailMessage
      )}`
    );
    setDisplayConfirmationBtn(true);
    //refBtnYes.current.scrollIntoView();

    // const response = dispatch(
    //   sendSaveTheDateMail({ emailsToContact, emailMessage })
    // );
    // if (response) {
    //   alert("Message Sent.");
    // }
    // alert("Message failed to send.");
  };
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: `${base_url}/guestInscription/?websiteId=${websites.currentWebsiteId}`,
    //https://console.firebase.google.com/project/dday-060941/authentication/settings
    // This must be true.
    handleCodeInApp: true,
    // iOS: {
    //   bundleId: 'com.example.ios'
    // },
    // android: {
    //   packageName: 'com.example.android',
    //   installApp: true,
    //   minimumVersion: '12'
    // },
    dynamicLinkDomain: dynamicLinkDomain 
    //https://console.firebase.google.com/project/dday-060941/durablelinks/links/https:~2F~2Fdday.page.link
  };

  const handleSubmitYes = async () => {
    const res = await dispatch(updateIsFirstEmailSend({ organizerId: organizers.currentOrganizerId, websiteId: websites.currentWebsiteId, emailsToContact}));
    //emailsToContact = ['clotildefauchille@gmail.com', 'clotilde.fauchille@3wa.io']
    for (const email of emailsToContact ){
      sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        console.log('yoooo')
        // The link was successfully sent. Inform the guest.
        // Save the email locally so you don't need to ask the guest for it again
        // if they open the link on the same device.
        //window.localStorage.setItem('emailForSignIn', 'clotildefauchille@gmail.com');
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("je passe là", error  )
      });
    }

    if(res.payload.status===200){
      setDisplayMailModal(false);
      const param = {
        organizerId: organizers.currentOrganizerId,
        websiteId: websites.currentWebsiteId,
        page: page + 1,
        limit: rowsPerPage,
        token
      };
      dispatch(getAllGuests(param));
    }
  }
  // const formats = [
  //   "header",
  //   "bold",
  //   "italic",
  //   "underline",
  //   "link",
  //   "undo",
  //   "redo",
  //   "list",
  // ];
  return (
    <Modal
      open={displayMailModal}
      onClose={(e, reason) => {
        if (reason !== "backdropClick") {
          setDisplayMailModal(false);
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h3"
          aria-owns={Boolean(anchorEl) ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
          onMouseLeave={() => setAnchorEl(null)}
        >
          1. Je veux envoyer un mail "save the date" à {numSelected}{" "}
          {numSelected > 1 ? "invités" : "invité"} via ma messagerie
        </Typography>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={() => setAnchorEl(null)}
          disableRestoreFocus
        >
          {emailsToContact &&
            emailsToContact.map((email, index) => (
              <Typography key={index} sx={{ p: 1 }}>
                {email}
              </Typography>
            ))}
        </Popover>
        <TextField
          sx={{ marginTop: "1rem", marginBottom: "1rem" }}
          fullWidth
          label="subject"
          id="mailSubject"
          defaultValue={`Save the Date !`}
          onChange={(e) => setEmailSubject(e.target.value)}
        />
        <TextField
          sx={{ marginTop: "1rem" }}
          fullWidth
          multiline
          label="message"
          id="mailMessage"
          value={emailMessage}
          onChange={(e) => setEmailMessage(e.target.value)}
        />
        
        <Stack sx={{ marginTop: "1rem" }} direction="row" spacing={2}>
          <Button
            startIcon={<SendIcon />}
            onClick={handleSubmitMailSend}
            variant="contained"
          >
            Envoyer depuis ma messagerie
          </Button>
          <Button variant="outlined" onClick={() => setDisplayMailModal(false)}>
            Annuler
          </Button>
        </Stack>
        {displayConfirmationBtn && (
          <>
            {" "}
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              aria-owns={Boolean(anchorEl) ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
              onMouseLeave={() => setAnchorEl(null)}
              sx={{ marginTop: "1rem" }}
            >
              2. Attention! JE CONFIRME avoir envoyé mon mail{" "}
              {numSelected > 1 ? "aux" : "à"} {numSelected}{" "}
              {numSelected > 1 ? "invités" : "invité"}:{" "}
            </Typography>{" "}
            <h6>
              <strong>
                Si OUI, une invitation via mail, va être envoyée à {numSelected > 1 ? "vos invités pour qu'ils s'inscrivent et se connectent à votre site" : "votre invité pour qu'il s'inscrive et se connecte à votre site."}
                
              </strong>
            </h6>
            <Stack sx={{ marginTop: "1rem" }} direction="row" spacing={2}>
              <Button
                ref={refBtnYes}
                onClick={handleSubmitYes}
                variant="contained"
              >
                Oui
              </Button>
              <Button
                variant="outlined"
                // onClick={() => setDisplayMailModal(false)}
              >
                Non
              </Button>
            </Stack>
          </>
        )}
      </Box>
    </Modal>
  );
};
export default MailSendModal;
