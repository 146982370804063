import React, { useState, useEffect } from "react";
//import profile from "../../assets/svg/profile.svg";
import Profile from "./profile";
import Menu from "../Menu/Menu.js";
import EditIcon from "@mui/icons-material/Edit";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import {
  Tooltip,
  IconButton,
  Typography,
  Button,
  Popover,
  Stack,
  Box
} from "@mui/material";
import AddWebsiteInfoForm from "./AddWebsiteInfoForm.js";
import { useSelector, useDispatch } from "react-redux";
import { getWebsiteInfos } from "../../../reducer/websites.js";
import "./Header.scss";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { setGuestsInitialState } from "../../../reducer/guests";
import { setWebsitesInitialState } from "../../../reducer/websites";
import { momentLocaleConfig } from "../../../utils/momentLocalConfig.js";
import moment from "moment";
import ColorEdit from "../Header/ColorEdit";

const Header = () => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  //const arrayDisplayName = currentUser.displayName.split('+');
  const websites = useSelector((state) => state.websites);
  //console.log("wcurrentUser", currentUser);
  const dispatch = useDispatch();
  const [displayAddWebsiteTitle, setDisplayAddWebsiteTitle] = useState(false);
  const [displayAddWebsiteDate, setDisplayAddWebsiteDate] = useState(false);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;
  
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  moment.updateLocale("fr", momentLocaleConfig);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogoutButton = () => {
    
    signOut(auth).then(function () { 
      dispatch(setGuestsInitialState());
      dispatch(setWebsitesInitialState());
      console.log('Signed Out');
      navigate('/');
}, function (error) { console.error('Sign Out Error', error); });
    // signOut(auth);
    // navigate("/");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnClickTitle = () => {
    setDisplayAddWebsiteTitle(true);
  };
  const handleOnClickDate = () => {
    setDisplayAddWebsiteDate(true);
  };

  useEffect(() => {
    dispatch(getWebsiteInfos(websites.websiteInfos.websiteId));
  }, []);

  return (
    <div>
      {websites.websiteInfos !== null && (
        <div className="header">
          <Menu />
          {!isMobile &&
          <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setOpen(true)}
          sx={{ ...(open && { display: "none" }) }}
        >
          <FormatPaintIcon
            style={{
              marginLeft: "0rem",
              color: websites.websiteInfos.titleColor,
            }}
          />
        </IconButton> 
          }
          {open && <ColorEdit setOpen={setOpen} open={open} />}
          <Typography variant="h1" sx={!isMobile ? {}:{textAlign:'center', p: 1}}>
            {websites.websiteInfos.title}
            {!open && 
            <Tooltip title="Edit">
              <IconButton onClick={handleOnClickTitle}>
                <EditIcon
                  style={!isMobile ? {
                    marginLeft: "0.5rem",
                    color: websites.websiteInfos.titleColor,
                  } : {
                    marginLeft: "0rem",
                    color: websites.websiteInfos.titleColor,
                  }}
                />
              </IconButton>
            </Tooltip>
            }
          </Typography>
          {displayAddWebsiteTitle && (
            <AddWebsiteInfoForm
              setDisplayAddWebsiteInfo={setDisplayAddWebsiteTitle}
              displayAddWebsiteInfo={displayAddWebsiteTitle}
              type="title"
            />
          )}
          <Box className="header__container-date-profile">
            <Typography variant="h2" sx={!isMobile ?{}:{ textAlign: 'center' }} className="header__date" >
              {moment(websites.websiteInfos.date).format("LL")}
              {!open &&
                <Tooltip title="Edit">
                  <IconButton onClick={handleOnClickDate}>
                    <EditIcon
                      style={!isMobile ? {
                        marginLeft: "0.5rem",
                        color: websites.websiteInfos.titleColor,
                      } : {
                        marginLeft: "0rem",
                        color: websites.websiteInfos.titleColor,
                      }
                      }
                    />
                  </IconButton>
                </Tooltip>
              }
            </Typography>
            {displayAddWebsiteDate && (
              <AddWebsiteInfoForm
                setDisplayAddWebsiteInfo={setDisplayAddWebsiteDate}
                displayAddWebsiteInfo={displayAddWebsiteDate}
                type="date"
              />
            )}
            <div>
              <Button
                size={!isMobile ? "large" : "small"}
                variant="contained"
                aria-describedby={id}
                onClick={handleClick}
              >
                <Profile></Profile>
                {/* <img className="header__icon" alt="disconnect" src={profile} /> */}
              </Button>
              <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                {currentUser && 
                <Stack spacing={2} sx={{padding: '1rem'}}>
                    <Typography sx={{ p: 1 }}> {currentUser.displayName.split('+')[0].trim()} {currentUser.displayName.split('+')[1].trim()}</Typography>
                  <Button
                    className="header__logout"
                    variant="contained"
                    onClick={handleLogoutButton}
                  >
                    Se Déconnecter
                  </Button>
                </Stack>
                
                }
              </Popover>
            </div>
          </Box>
        </div>
      )}
    </div>
  );
};
export default Header;
