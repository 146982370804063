import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = process.env.NODE_ENV === "production" ? `${process.env.REACT_APP_API_PROD}` : `${process.env.REACT_APP_API_DEV}`


export const events = createSlice({
  name: "events",
  initialState: {},
  reducers: {
    // addOneEvent: (state, action) => {
    //   console.log("action.payload", action.payload);
    //   state.events = [...state.events, action.payload];
    // },
  },
  extraReducers(builder) {
    builder.addCase(getAllEvents.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export const {} = events.actions;

export const getAllEvents = createAsyncThunk(
  "events/getAllEvents",
  async (body) => {
    const { websiteId, token } = body;
    const url = `${base_url}/api/events/${websiteId}`;
    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        "websiteid": websiteId
      }
    try {
      const response = await axios.get(url, {headers});
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const createOrUpdateOneEvent = createAsyncThunk(
  "events/createOrUpdateOneEvent",
  async (body) => {
    const { token, website_id } = body;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
      "websiteid": website_id
    }
    const url = `${base_url}/api/events`;
    try {
      const response = await axios.put(url, body, { headers });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const deleteOneEvent = createAsyncThunk(
  "events/deleteOneEvent",
  async (body) => {
    const { websiteId, eventId , token} = body;
    const url = `${base_url}/api/events/${eventId}`;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
      "websiteid": websiteId
    }
    try {
      const response = await axios.delete(url, {headers});
      return response.data;
    } catch (error) {
      return false;
    }
  }
);

// export const updateOneEvent = createAsyncThunk(
//   "events/updateOneEvent",
//   async () => {
//     const url = `http://localhost:5000/api/events`;
//     try {
//       const response = await axios.put(url);
//       console.log("response", response.data);
//       return response.data;
//     } catch (error) {
//       return false;
//     }
//   }
// );
export default events.reducer;
