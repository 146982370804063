import React, { useState, useEffect } from "react";
import { placeIcons, placeIconsInfos } from "../../../reducer/icons/icons";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addOnePlaceIfNotExist, getAllPlaces } from "../../../reducer/places.js";
import { formatAdress } from '../../../utils/map.js';
import {
  Button,
  Stack,
  TextField,
  Paper,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText, 
} from "@mui/material";
import PropTypes from "prop-types";

//TODO alert already exist + sucess lors de l'ajout 
const AddPlace = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.users.token);
  const { setDisplayAddPlace, setAlert } = props;
  const [streetName, setStreetName] = useState({ errorText: "", value: null});
  const [zipCode, setZipCode] = useState({ errorText: "", value: null });
  const [city, setCity] = useState({ errorText: "", value: null });
  const [icon, setIcon] = useState({ errorText: "", value: null });
  const [name, setName] = useState({ errorText: "", value: null });
  const websites = useSelector((state) => state.websites);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  const handleSubmitAddOnePlace = async () => {
    if (streetName.value === null || streetName.value.length === 0) {
      setStreetName({ errorText: "Indiquez l'adresse ", value: null });
      return;
    }
    
    if (zipCode.value === null || zipCode.value.length === 0) {
      setZipCode({ errorText: "Indiquez le code postal ", value: null });
      return;
    }
    if (city.value === null || city.value.length === 0) {
      setCity({ errorText: "Indiquez la ville ", value: null });
      return;
    }
    if (icon.value === null || icon.value.length === 0) {
      setIcon({ errorText: "Selectionner une icône ", value: null });
      return;
    }
    const completeAdressFormated = formatAdress(streetName.value);
    //console.log("icon.value", icon.value);
    //console.log("websites.currentWebsiteId", websites.currentWebsiteId);

    const body = {
      token,
      completeAdressFormated,
      zipCode: zipCode.value,
      infos: { websiteId: websites.currentWebsiteId, name: name.value, streetName: streetName.value, zipCode: zipCode.value, city: city.value, addedIcon: icon.value },
    };
    const res = await dispatch(addOnePlaceIfNotExist(body));
    //console.log(res.payload)
    if (res.payload.status === 200 && res.payload.message) {
      setAlert({
        display: true,
        message: res.payload.message,
        type: "success",
      });
      const body2 = {
        websiteId: websites.currentWebsiteId,
        token,
      }
      dispatch(getAllPlaces(body2));
      setDisplayAddPlace(false);
    }
    if(res.payload.status==="error"){
      setAlert({
        display: true,
        message: "l'adresse n'est pas reconnue, veuillez saisir une adresse valide",
        type: "error",
      });
    }
  };

  return (
    <Paper sx={!isMobile ? { ml: 4, mr: 4, mb: 4, p: 2 } : { mb: 2, p: 1, mt:2, m:1 }}>
      <Stack direction="row" spacing={2} sx={{ mt: 2}}>
        <TextField
          label="Titre"
          variant="standard"
          helperText={name.errorText}
          onChange={(e) => setName({ errorText: "", value: e.target.value })}
          required
          sx={{ width: "40%", pb: 1 }}
          error={name.errorText.length === 0 ? false : true}
          inputProps={{ maxLength: 40 }}
        />
        <TextField
          label="Adresse"
          variant="standard"
          helperText={streetName.errorText}
          onChange={(e) => setStreetName({ errorText: "", value: e.target.value})}
          required
          sx={{ width: "60%" }}
          error={streetName.errorText.length === 0 ? false : true}
        />
      </Stack>
      <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
        <TextField
          label="Code Postal"
          variant="standard"
          helperText={zipCode.errorText}
          onChange={(e) => setZipCode({ errorText: "", value: e.target.value})}
          required
          sx={!isMobile ? { width: "20%", pb: 1 } : { width: "50%", pb: 1 }}
          error={zipCode.errorText.length === 0 ? false : true}
          inputProps={{ maxLength: 12 }}
        />
        <TextField
          label="Ville"
          variant="standard"
          helperText={city.errorText}
          onChange={(e) => setCity({ errorText: "", value: e.target.value})}
          required
          sx={!isMobile ? { width: "40%", pb: 1 } : { width: "50%", pb: 1 }}
          error={city.errorText.length === 0 ? false : true}
        />
      </Stack>
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Stack>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            onChange={(e) => setIcon({ errorText: "", value: e.target.value})}
            row
            sx={{ width: "100%", pb: 2, pt: 2 }}
          >
            {placeIconsInfos.map((icon, index) => {
              return (
                <div key={index}>
                  <FormControlLabel
                    control={<Radio />}
                    label={
                      <Fragment>
                        <img
                          src={placeIcons[icon.name]}
                          alt={icon.label}
                          // key={id}
                          width="25rem"
                          height="auto"
                          style={{ marginRight: "5px" }}
                        />
                        {icon.label}
                      </Fragment>
                    }
                    value={icon.name}
                  />
                </div>
              );
            })}
          </RadioGroup>
          <FormHelperText sx={{ color: "#de9999" }}>
            <b> {icon.errorText}</b>
          </FormHelperText>
        </Stack>
        <Stack direction={!isMobile ? "row" : "column"} spacing={2}>
          <Button
            variant="contained"
            type="submit"
            sx={!isMobile ? {height: '70%'}: {height: '30%', }}
            onClick={handleSubmitAddOnePlace}
          >
            Ajouter
          </Button>
          <Button
            sx={!isMobile ? {height: '70%'}: {height: '30%'}}
            variant="outlined"
            type="submit"
            onClick={() => { setAlert(false); setDisplayAddPlace(false)}}
          >
            Annuler
          </Button>
        </Stack>
</Stack>
      
    </Paper>
  );
};

AddPlace.propTypes = {
  setAlert: PropTypes.func.isRequired,
  setDisplayAddPlace: PropTypes.func.isRequired
};

export default AddPlace;
