import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import LocationPin from "./LocationPin.js";
import PageTitle from "../../PageTitle/PageTitle";
import { getAllPlaces } from "../../../reducer/places.js";
import { useDispatch, useSelector } from "react-redux";
import {Box, Button} from "@mui/material";
import AlertMessage from '../../AlertMessage/AlertMessage.js';
import HeaderGuest from '../HeaderGuest/HeaderGuest.js';

const MapsPageGuest = () => {
  const dispatch = useDispatch();
  const places = useSelector((state) => state.places);
  const websites = useSelector((state) => state.websites);
  const token = useSelector((state) => state.users.token);
  const [alert, setAlert] = useState({
    display: false,
    message: null,
    type: null,
  });
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  
  useEffect(() => {
    const fetchAllPlaces = async () => {
      const body = {
        websiteId: websites.currentWebsiteId,
        token,
      }
      await dispatch(getAllPlaces(body))
    };
    if (token !== null) {
    fetchAllPlaces();
    }
  }, []);

 
  
  return (
    <Box >
      <HeaderGuest />
      <PageTitle name="Infos Pratiques" />
      {alert.display && (
        <AlertMessage setAlert={setAlert} alert={alert} />
      )}
      {places && places.length>=0 &&
      <Box sx={!isMobile ? { flexGrow: 1, pl: 4, pr: 4, pb: 2 } : { flexGrow: 1, pl: 1, pr: 1, pb: 2 }}>
          <div className="google-map" style={{ height: "70vh" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_API_KEY_MAPS_JS }}
              defaultCenter={{ lat: places.length == 0 ? 48.87372135445968 : Number(places[0].lat), lng: places.length == 0 ? 2.419590527175259 : Number(places[0].lng) }} 
              defaultZoom={11}
            >
              {places.length > 0 &&
                places.map((place, index) => (
                  <LocationPin key={index} lat={place.lat} lng={place.lng} place={place}/>
                ))}
            </GoogleMapReact>
          </div>
      </Box>
      }
    </Box>
  );
};

export default MapsPageGuest;
