import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
//https://redux.js.org/tutorials/essentials/part-2-app-structure
//grace a redux/toolkit, on peut ecrire du code mutable car sous le capot utilise timer qui rend le code immutable(...state, copie de l'bjet ou array avt)
//on peut aussi se passer d'ecrire les actions; elles seront directement utilisées avec le type commme name indiqué
//payload correspond à la valeur passé dans l'action dans le dispatch du composant
const initialState = {
  isAnyGuestDeleteAction: false,
  isAnyGuestAddingAction: false,
  guests: {},
  allGuests: {},
  page: 0,
  rowsPerPage: 5,
}
const base_url = process.env.NODE_ENV === "production" ? `${process.env.REACT_APP_API_PROD}` : `${process.env.REACT_APP_API_DEV}`

export const guests = createSlice({
  name: "guests",
  initialState,
  reducers: {
    setGuestsInitialState: (state) => initialState,
    setIsAnyGuestDeleteAction: (state, action) => {
      state.isAnyGuestDeleteAction = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setIsAnyGuestAddingAction: (state, action) => {
      state.isAnyGuestAddingAction = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(getAllGuests.fulfilled, (state, action) => {
       state.guests = action.payload;
    });
    builder.addCase(getAllGuestsNoLimit.fulfilled, (state, action) => {
      state.allGuests = action.payload;
    });
  },
});

export const { setIsAnyGuestDeleteAction, setIsAnyGuestAddingAction, setPage, setRowsPerPage, setGuestsInitialState } = guests.actions;

// export const updateGuestRsvp = createAsyncThunk(
//   "guests/updateGuestRsvp",
//   async (param) => {
//     try {
//       const response = await fetch("url", {
//         method: "PUT",
//         headers: {
//           "Content-type": "application/json",
//         },
//         body: JSON.stringify({ param }),
//       });
//       return await response.json();
//     } catch (error) {
//       return false;
//     }
//   }
// );
export const addOneGuest = createAsyncThunk(
  "guests/addOneGuest",
  
  async (body) => {
    try {
      //console.log(body);
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${body.token}`,
        "websiteid": body.websiteId
      }
      const {organizerId, websiteId} = body;
      const response = await axios.post(`${base_url}/api/guests/addOne/${organizerId}/${websiteId}`, body, {headers});
      //console.log(response.data)
      return await response.data;
    } catch (error) {
      return false;
    }
  }
);
export const sendSaveTheDateMail = createAsyncThunk(
  "guests/sendSaveTheDateMail",
  async ({ emailsToContact, emailMessage }) => {
    try {
      const response = await fetch(`${base_url}/mail/send`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ emailsToContact, emailMessage }),
      });
      return await response.json();
    } catch (error) {
      return false;
    }
  }
);

export const getAllGuests = createAsyncThunk(
  "guests/getAllGuests",
  async (param) => {
    try {
      const { organizerId, websiteId, token } = param;
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        "websiteid": websiteId
      }
      const response = await axios.get(
        `${base_url}/api/guests/${websiteId}/${organizerId}/?page=${param.page}&limit=${param.limit}`, {headers}
      );
      return response.data;
    } catch (error) {
      return false;
    }
  }
);

export const getAllGuestsNoLimit = createAsyncThunk(
  "guests/getAllGuestsNoLimit",
  async (body) => {
    try {
      const { websiteId, organizerId, token } = body;
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        "websiteid": websiteId
      }
      const response = await axios.get(`${base_url}/api/guests/${websiteId}/${organizerId}`, {headers});
      return response.data.result;
    } catch (error) {
      return false;
    }
  }
);
export const deleteSelectedGuests = createAsyncThunk(
  "guests/deleteSelectedGuests",
  async (body) => {
    try {
      const { selectedIdGuests, organizerId, websiteId, token} = body;
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        "websiteid": websiteId
      }
      const res = await axios.post(`${base_url}/api/guests`, body, {headers});
      //console.log("res", res);
      return res.data;
    } catch (error) {
      return false;
    }
  }
);

export const addGuestsFromCsvList = createAsyncThunk(
  "guests/addGuestsFromCsvList",
  async (body) => {
    try {
      const { token, websiteId } = body;
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        "websiteid": websiteId
      }
      const res = await axios.post(`${base_url}/api/guests/import/csv`, body, { headers } );
      console.log("res", res.data);
      return res.data;
    } catch (error) {
      return false;
    }
  }
);

export const updateIsFirstEmailSend = createAsyncThunk(
  "guests/updateIsFirstEmailSend",
  async (body) => {
    try {
      const { websiteId, organizerId, emailsToContact } = body;
      const res = await axios.put(`${base_url}/api/guests/${websiteId}/${organizerId}`, emailsToContact);
      return res.data;
    } catch (error) {
      return false;
    }
  }
)
export const isFirstEmailSend = createAsyncThunk(
  "guests/isFirstEmailSend",
  async (body) => {
    try {
      const { websiteId, organizerId, emailsToContact } = body;
      const res = await axios.post(`${base_url}/api/guests/isFirstEmailSend/${websiteId}/${organizerId}`, emailsToContact);
      console.log(res.data)
      return res.data;
    } catch (error) {
      return false;
    }
  }
)

export const createGuestRole = createAsyncThunk(
  "guests/createGuestRole",
  async (body) => {
    try {
      //console.log("uid", body)
      const response = await axios.post(`${base_url}/role/setGuest`, body);
      return response.data;
    } catch (error) {
      return false;
    }
  }
);

export const addGuestCustomClaimsIfUserAnyClaims = createAsyncThunk(
  "guests/addGuestCustomClaimsIfUserAnyClaims",
  async (body) => {
    try {
      const response = await axios.post(`${base_url}/role/addGuestCustomClaimsIfUserAnyClaims`, body);
      return response.data;
    } catch (error) {
      return false;
    }
  }
)

export const getOrganizerFriends = createAsyncThunk(
  "guests/getOrganizerFriends",
  async (websiteId, organizerId) => {
    try {
      //console.log("uid", body)
      const response = await axios.get(`${base_url}/organizerFriends/${websiteId}/${organizerId}`);
      return response.data;
    } catch (error) {
      return false;
    }
  }
);
export const getGroupData = createAsyncThunk(
  "guests/getGroupData",
  async (body) => {
    try {
      const { websiteId, organizerId, organizerName, organizerPartnerName } = body;
      const response = await axios.get(`${base_url}/api/guests/groupeData/${websiteId}/${organizerId}/${organizerName}/${organizerPartnerName}`);
      return response.data;
    } catch (error) {
      return false;
    }
  }
);

export const getConnectedGuestId = createAsyncThunk(
  "guests/getConnectedGuestId",
  async (body) => {
    try {
      const response = await axios.post(`${base_url}/api/guests/getConnectedGuestId`, body);
      return response.data;
    } catch (error) {
      return false
    }
  }
)

export const sendRsvpGuest = createAsyncThunk(
  "guests/sendRsvpGuest",
  async (body) => {
    try {
      const { token, websiteId } = body;
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        "websiteid": websiteId
      }
      const res = await axios.put(`${base_url}/api/guests/updateRsvp`, body, {headers})
      //console.log("res", res.data);
      return res.data;
    } catch (error) {
      return false;
    }
  }
)

export const setLastLoginGuest = createAsyncThunk(
  "guests/setLastLoginGuest",
  async (body) => {
    try {
      const res = await axios.put(`${base_url}/api/guests/setLastLoginGuest`, body);
      return res.data;
    } catch (error) {
      return false;
    }
  }
)

export const updateGroupFromGuest = createAsyncThunk(
  "guests/updateGroupFromGuest",
  async (body) => {
    try {
      const res = await axios.put(`${base_url}/api/guests/updateGroupe`, body);
      return res.data;
    } catch (error) {
      return false;
    }
  }
)

export default guests.reducer;

//comment savoir quand utiliser redux state ou le state local; se poser les questions suivantes:
// D'autres parties de l'application s'intéressent-elles à ces données ?
// Avez - vous besoin de pouvoir créer d'autres données dérivées basées sur ces données d'origine ?
//Les mêmes données sont - elles utilisées pour piloter plusieurs composants ?
// Est - il utile pour vous de pouvoir restaurer cet état à un moment donné(c'est-à-dire, le débogage du voyage dans le temps) ?
// Voulez - vous mettre les données en cache(c'est-à-dire utiliser ce qui est dans l'état s'il est déjà là au lieu de le redemander) ?
// Souhaitez - vous que ces données restent cohérentes lors du rechargement à chaud des composants de l'interface utilisateur (qui peuvent perdre leur état interne lorsqu'ils sont échangés) ?

// C'est aussi un bon exemple de la façon de penser aux formulaires dans Redux en général. La plupart des états de formulaire ne devraient probablement pas être conservés dans Redux. Au lieu de cela, conservez les données dans vos composants de formulaire pendant que vous les modifiez, puis envoyez des actions Redux pour mettre à jour le magasin lorsque l'utilisateur a terminé.
