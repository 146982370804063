import React, { useState, useEffect } from "react";
//import profile from "../../assets/svg/profile.svg";
import Profile from './profile';
import MenuGuest from "../MenuGuest/MenuGuest";
import { Typography, Button, Popover, Stack, Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getWebsiteInfos } from "../../../reducer/websites.js";
import "./HeaderGuest.scss";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { setGuestsInitialState, setLastLoginGuest } from '../../../reducer/guests';
import { setWebsitesInitialState } from '../../../reducer/websites';
import moment from "moment";
import { momentLocaleConfig } from "../../../utils/momentLocalConfig.js";


const HeaderGuest = () => {
  const auth = getAuth();
  moment.updateLocale("fr", momentLocaleConfig);
  const currentUser = auth.currentUser;
  const lastLoginCurrentGuest = currentUser.metadata.lastLoginAt;
  const emailCurrentGuest = currentUser.email;
  const arrayDisplayName= currentUser.displayName.split('+');  
  const guests = useSelector((state)=>state.guests);
  const websites = useSelector((state) => state.websites);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleLogoutButton = () => {
    signOut(auth).then(function () { 
      dispatch(setGuestsInitialState());
      dispatch(setWebsitesInitialState());
      navigate(`/guestInscription/?websiteId=${websites.currentWebsiteId}`);
    }, function (error) { console.error('Sign Out Error', error); });
}

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  useEffect(() => {
    dispatch(getWebsiteInfos(websites.websiteInfos.websiteId));
    const body = {
      websiteId: websites.websiteInfos.websiteId,
      emailCurrentGuest,
      lastLoginDate: lastLoginCurrentGuest
    }
    dispatch(setLastLoginGuest(body))
  }, []);

  return (
    <div>
      {websites.websiteInfos !== null && (
        <div className="header">
          <MenuGuest />
          <Typography variant="h1" className="header__title" sx={!isMobile ? {} : { textAlign: 'center', p: 1 }}>
            {websites.websiteInfos.title}
          </Typography>
          <div className="header__container-date-profile">
            <Typography variant="h2" className="header__date" sx={!isMobile ? {} : { textAlign: 'center' }}>
              {moment(websites.websiteInfos.date).format("LL")}
            </Typography>
            {/* {!isMobile &&
            <div > */}
            <Box className="header__logout-container">
              <Button
                variant="contained"
                aria-describedby={id}
                onClick={handleClick}
                size={isMobile ? "small" : "large"}
              >
                <Profile></Profile>
              </Button>
              <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Stack spacing={2} sx={{padding: '1rem'}}>
                  <Typography sx={{ p: 1 }}> {arrayDisplayName[0].trim()} {arrayDisplayName[1].trim()}</Typography>
                  <Button
                    variant="contained"
                    onClick={handleLogoutButton}
                  >
                    Se Déconnecter
                  </Button>
                </Stack>
              </Popover>
            </Box>
            {/* </div>
            } */}
            

          </div>
        </div>
      )}
    </div>
  );
};
export default HeaderGuest;
