import React, { useState, useEffect } from "react";
import GuestPage from "../pagesOrganizer/GuestPage/GuestPage";
import { ThemeProvider } from "@mui/material/styles";
import { Route, Routes, useNavigate } from "react-router-dom";
import AgendaPage from "../pagesOrganizer/AgendaPage/AgendaPage";
import AgendaPageGuest from "../pagesGuest/AgendaPageGuest/AgendaPageGuest";
import RsvpPage from "../pagesOrganizer/RsvpPage/RsvpPage.js";
import RsvpPageGuest from "../pagesGuest/RsvpPageGuest/RsvpPageGuest";
import MapsPage from "../pagesOrganizer/MapsPage/MapsPage.js";
import MapsPageGuest from "../pagesGuest/MapsPageGuest/MapsPageGuest";
import HomePageOrganizer from "../HomeOrganizer/HomePageOrganizer.js";
import firebase from "../../services/firebase";
import HomeGuest from "../HomeGuest/HomeGuest.js";
import LivreOr from "../pagesOrganizer/LivreOr/LivreOr.js";
import LivreOrGuest from "../pagesGuest/LivreOrGuest/LivreOrGuest";
import { useDispatch, useSelector } from "react-redux";
import { getWebsiteInfos } from "../../reducer/websites.js";
import CssBaseline from "@mui/material/CssBaseline";
import { getAuth } from "firebase/auth";
import {
  setCurrentOrganizerEmail,
  getCurrentOrganizerId,
} from "../../reducer/organizers";
import { setCurrentWebsiteId, setIsLoading } from "../../reducer/websites";
import Loader from "../Loader/Loader";
import NotFound from "../NotFound/NotFound";
import { theme } from "../../utils/website";
import { useSearchParams } from "react-router-dom";
import { setToken } from "../../reducer/users";

const App = () => {
  const navigate = useNavigate();
  const websites = useSelector((state) => state.websites);
  const organizers = useSelector((state) => state.organizers);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const websiteIdUrl = searchParams.get("websiteId");

  const fetchWebsiteInfos = async (websiteId) => {
    //pour obtenir le bon website (mariage) il faut le descriminant qui est l'le websiteId; le guest aura l'info dans l'url de redirection dans le mail envoyer lors de l'invitation. on met un uit pour le website id pour lus de secu
    //à l'inscription on recupere cet info dans l'url
    if (websiteId !== null) {
      await dispatch(getWebsiteInfos(websiteId));
    }
  };

  useEffect(() => {
    fetchWebsiteInfos(websites.currentWebsiteId);
  }, [websites.currentWebsiteId]);

  useEffect(() => {
    const fetchCurrentOrganizerId = async () => {
      await dispatch(getCurrentOrganizerId(organizers.currentOrganizerEmail));
    };
    try {
      if (organizers.currentOrganizerEmail !== null) {
        fetchCurrentOrganizerId();
      }
    } catch (error) {
      console.log(error);
    }
  }, [organizers.currentOrganizerEmail]);

  useEffect(() => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (currentUser == null) {
      dispatch(setIsLoading(false));
    }
    //console.log("currentUser", currentUser);

    firebase.auth().onAuthStateChanged((user) => {
      // console.log("currentWebsiteId", websites.currentWebsiteId);
      // au refresh d'un  utilisateur connecté, on récupère le websiteId depuis l'url

      if (
        (user !== null && websites.currentWebsiteId !== null) ||
        (user !== null && websiteIdUrl !== "null" && websiteIdUrl !== null)
      ) {
        if (websiteIdUrl !== "null" && websiteIdUrl !== null) {
          fetchWebsiteInfos(websiteIdUrl);
        } 
          user
            .getIdTokenResult(true)
            .then(function (idToken) {
              console.log("idToken", idToken, "userrrrrr", user);

              //on regarde quel role pour ce websiteId est enregistré
              const isOneRoleMatchToCurentWebsiteId =
                idToken.claims.userTypes.filter((userType) => {
                  return (
                    userType.websiteId === websites.currentWebsiteId ||
                    userType.websiteId === websiteIdUrl
                  );
                });

              if (isOneRoleMatchToCurentWebsiteId[0].role === "organizer") {
                dispatch(setCurrentOrganizerEmail(idToken.claims.email));
                dispatch(
                  setCurrentWebsiteId(
                    isOneRoleMatchToCurentWebsiteId[0].websiteId
                  )
                );
                dispatch(setToken(user.multiFactor.user.accessToken));
                navigate(`/agenda/?websiteId=${websites.currentWebsiteId}`);
                dispatch(setIsLoading(false));
              } else if (isOneRoleMatchToCurentWebsiteId[0].role === "guest") {
                //dispatch(setCurrentOrganizerEmail(idToken.claims.email));
                dispatch(
                  setCurrentWebsiteId(
                    isOneRoleMatchToCurentWebsiteId[0].websiteId
                  )
                );
                dispatch(setToken(user.multiFactor.user.accessToken));
                navigate(
                  `/agendaGuest/?websiteId=${websites.currentWebsiteId}`
                );
                dispatch(setIsLoading(false));
              } else {
                navigate("/");
                dispatch(setIsLoading(false));
              }
            })
            .catch(function (error) {
              console.log("error", error);
              navigate("/");
            });
      }
    });
  }, [websites.currentWebsiteId]);

  return (
    <div>
      <ThemeProvider theme={theme(websites)}>
        <CssBaseline />
        {websites.isLoading ? (
          <Loader />
        ) : (
          <>
            <Routes>
              <Route path="/" element={<HomePageOrganizer />}></Route>
              <Route path="/guestInscription" element={<HomeGuest />}></Route>
              {websites.websiteInfos !== null && (
                <>
                  <Route
                    path="/agendaGuest"
                    element={<AgendaPageGuest />}
                  ></Route>
                  <Route
                    path="/confirmationGuest"
                    element={<RsvpPageGuest />}
                  ></Route>
                  <Route path="/infosGuest" element={<MapsPageGuest />}></Route>
                  <Route
                    path="/livreOrGuest"
                    element={<LivreOrGuest />}
                  ></Route>

                  <Route path={`/agenda`} element={<AgendaPage />}></Route>
                  <Route path="/confirmation" element={<RsvpPage />}></Route>
                  <Route path="/infos" element={<MapsPage />}></Route>
                  <Route path="/livreOr" element={<LivreOr />}></Route>
                  <Route path="/guest" element={<GuestPage />}></Route>
                  <Route path="*" element={<NotFound />} />
                </>
              )}
            </Routes>
          </>
        )}
      </ThemeProvider>
    </div>
  );
};
export default App;
