import { createSlice } from '@reduxjs/toolkit'

export const displayGuestPage = createSlice({
    name: 'displayGuestPage',
    initialState: {
        displayForm : false,
        disabledBtnAddGuest : false, 
        messageAlert: {}
    },
    reducers: {
        displayGuestForm: (state, action) => {
            state.displayForm = action.payload;
        },
        setDisabledBtnAddGuest: (state, action)=> {
            state.disabledBtnAddGuest = action.payload;
        },
        setMessageAlert : (state, action) => {
            state.messageAlert=action.payload;
        }
    },
})

export const { displayGuestForm, setDisabledBtnAddGuest, setMessageAlert } = displayGuestPage.actions


export default displayGuestPage.reducer

