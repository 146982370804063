import React, { useState } from "react";
import PropTypes from "prop-types";
import { TableCell, Box, TableHead, TableRow, TableSortLabel, Checkbox } from "@mui/material/";
import { visuallyHidden } from "@mui/utils";
import { guestTableHeadFilter, labelGuestTableHead } from "../../../utils/guest.js";
const GuestTableHead = (props) => {
  //pour retirer les id du tableau
  //const guestInfos = allGuestInfos.map(({id, ...rest }) => ({ ...rest })); 
  const [totalWillBePresent, setTotalWillBePresent] = useState(0);

  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
            
    <TableHead >
      <TableRow >
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {labelGuestTableHead.map((headCell) => {
          return (
            <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              sx={{ width: '80px' }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
                {headCell.label === "willBePresent" ? `willBePresent(${totalWillBePresent})` :  headCell.label }
              
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) 
              : null
              }
            </TableSortLabel>
          </TableCell>)}
        )}
      </TableRow>
    </TableHead>
  );
};
export default GuestTableHead;

GuestTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  // onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

