import React, { useEffect, useState } from "react";
import {
  Paper,
  Stack,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { deleteOnePlace, getPlaceToBeInDeleteList } from "../../../reducer/places.js";

const DeletePlace = ({ setOpen, setAlert }) => {
  const [listPlacesToDelete, setListPlacesToDelete] = useState([]);
  const dispatch = useDispatch();
  const websites = useSelector((state) => state.websites);
  const token = useSelector((state) => state.users.token);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    const fetchPlaceForDeleteList = async () => {
      const body = {
        token,
        websiteId: websites.currentWebsiteId
      }
      const res = await dispatch(getPlaceToBeInDeleteList(body));
      if (res.payload.length > 0) {
        setListPlacesToDelete(res.payload);
      }
    };
    fetchPlaceForDeleteList();
  }, []);
  
  const handleDeletePlaceFromList = async (e) => {
    const body2= {
      placeId: e.currentTarget.id,
      websiteId: websites.currentWebsiteId,
      token

    }
    const res = await dispatch(deleteOnePlace(body2));
    if (res.payload.status === 200) {
      setAlert({
        display: true,
        message: "Votre adresse a bien été supprimée",
        type: "success",
      });
      setOpen(false);
    } else {
        setAlert({
            display: true,
            message: "Votre adresse n'a pas été supprimée",
            type: "error",
        }); 
    }
  };
  return (
    <Paper sx={!isMobile ? { mb: 2, p: 2, mt: 2 , ml: 4, mr:4}: { mb: 2, p: 2, m:1 }}>
      <Stack display='flex' justifyContent='space-between' direction="row" spacing={2}>
        <List>
          {listPlacesToDelete.length>0 ? 
            listPlacesToDelete.map((item) => (
              <ListItem key={item.placeId} disablePadding>
                <ListItemButton
                  id={item.placeId}
                  onClick={handleDeletePlaceFromList}
                >
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      item.addedIcon == "bed"
                        ? "Logement" +
                          ": " +
                          item.name +
                          ", " +
                          item.streetName +
                          ", " +
                          item.zipCode +
                          ", " +
                          item.city
                        : "Tourisme" +
                          ": " +
                          item.name +
                          ", " +
                          item.streetName +
                          ", " +
                          item.zipCode +
                          ", " +
                          item.city
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))
            : <p>Vous n'avez pas ajouté d'adresse de logement ou de lieux à visiter </p>
            }
        </List>

        <Button
          startIcon={<CloseIcon />}
          sx={!isMobile ? { height: "70%" } : {height: "70%", pr: 3, pl: 3}}
          variant="outlined"
          size="small"
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
      </Stack>
    </Paper>
  );
};
export default DeletePlace;
