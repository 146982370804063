import React, { useRef, useState, useEffect } from "react";
import PageTitle from "../../PageTitle/PageTitle";
import { useSelector, useDispatch } from "react-redux";
import img from "../../../assets/img/rsvp.jpg";
import "./RsvpPage.scss";
import SendIcon from "@mui/icons-material/Send";
import Header from "../Header/Header";
import {
  Grid,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Stack,
  Button,
  Paper,
  Typography
} from "@mui/material";


//TODO verifier si les valeur ne sont pas null, gestio des messages

const RsvpPage = () => {
  const connectedGuestName = useSelector((state) => state.guests);
  const inputFileImageRef = useRef(null);
  const [imageUploaded, setImageUploaded] = useState(img);
  const [isGuestWillBePresent, setIsGuestWillBePresent] = useState(false);
  const [isPartnerWillBePresent, setIsPartnerWillBePresent] = useState(false);
  const [numberOfChildren, setNumberOfChildren] =  useState(null);
  const [aboutFoodRemark, setAboutFoodRemark] = useState("");
  const [partnerFirstName, setPartnerFirstName] = useState("");
  const [partnerLastName, setPartnerLastName] = useState("");
  const dispatch = useDispatch();

  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
 
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);


  const handleOnchangeGuestPresence = (e) => {
    let res = e.target.value;
    res === "oui"
      ? setIsGuestWillBePresent(true)
      : setIsGuestWillBePresent(false);
  };

  const handleOnchangePartnerPresence = (e) => {
    let res = e.target.value;
    res === "oui"
      ? setIsPartnerWillBePresent(true)
      : setIsPartnerWillBePresent(false);
  };


  return (
    <div>
      <Header />
      <PageTitle name="RSVP"/>
      <Typography sx={{m:2}}><i>Voilà le formulaire que devra remplir chacun de vos invité sur cette page :</i></Typography>
      <Grid sx={!isMobile ? { display: "flex", flexDirection: "row", pb:4 } : { display: "flex", flexDirection: "row", pb: '3rem'}}>
        <Paper
          sx={!isMobile ? { padding: '2rem', ml: 4, display: "flex", flexDirection: "column", width: '90%'} : { padding: '1rem', m:2, display: "flex", flexDirection: "column", width: '100%'}}
        >
          <FormControl sx={{ mb: 4 }}>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Je confirme ma présence :
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={handleOnchangeGuestPresence}
            >
              <FormControlLabel value="oui" control={<Radio />} label="oui" />
              <FormControlLabel value="non" control={<Radio />} label="non" />
            </RadioGroup>
          </FormControl>
          <FormControl sx={{ mb: 4 }}>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Je confirme la présence de mon +1:
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={handleOnchangePartnerPresence}
            >
              <FormControlLabel value="oui" control={<Radio />} label="oui" />
              <FormControlLabel value="non" control={<Radio />} label="non" />
            </RadioGroup>
            {isPartnerWillBePresent && (
              <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
                <TextField
                  label="Prénom"
                  variant="standard"
                  required
                  sx={!isMobile ? { width: "30%", pb: 1 } : { width: "50%", pb: 1 }}
                  onChange={(e)=>setPartnerFirstName(e.target.value)}
                />
                <TextField
                  label="Nom"
                  variant="standard"
                  required
                  sx={!isMobile ?{ width: "40%", pb: 1 } : {width: "50%", pb: 1 }}
                  onChange={(e)=>setPartnerLastName(e.target.value)}
                />
              </Stack>
            )}
          </FormControl>
          <FormControl sx={{ mb: 4 }}>
            <FormLabel sx={{ mb: 1 }} id="demo-row-radio-buttons-group-label">
              J'indique le nombre d'enfants qui m'accompagnent :
            </FormLabel>
            <TextField
              sx={!isMobile ? { width: "20%" } : { width: "50%" }}
              id="standard-number"
              label="Nombre d'enfants"
              type="number"
              defaultValue="0"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              onChange={(e)=>setNumberOfChildren(parseInt(e.target.value))}
            />
          </FormControl>
          <FormControl sx={{ mb: 4 }}>
            <FormLabel sx={{ mb: 1 }} id="demo-row-radio-buttons-group-label">
              J'indique mes remarques (régime particulier, allergies, autres) :
            </FormLabel>
            <TextField
              id="standard-multiline-static"
              label="Mes remarques"
              multiline
              rows={3}
              variant="standard"
              onChange={(e)=>setAboutFoodRemark(e.target.value)}
            />
          </FormControl>
        
            <Button
              sx={!isMobile ? {width: '25%'} : {width: '60%'}}
              startIcon={<SendIcon />}
              variant="contained"
            >
              Envoyer ma réponse
            </Button>
        </Paper>
      </Grid>
    </div>
  );
};
export default RsvpPage;
