import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { formatAdress } from "../utils/map.js";
const base_url = process.env.NODE_ENV === "production" ? `${process.env.REACT_APP_API_PROD}` : `${process.env.REACT_APP_API_DEV}`

export const places = createSlice({
  name: "places",
  initialState: {
    places: [],
  },
  reducers: {

  },
  extraReducers(builder) {
    builder.addCase(getAllPlaces.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export const {} = places.actions;

export const addOnePlaceIfNotExist = createAsyncThunk(
  "places/addOnePlaceIfNotExist",
  async (body) => {
    //const url = `https://maps.googleapis.com/maps/api/geocode/json?address=mairie+de+bagnolet+Place+Salvador+Allende&components=postal_code:93170|country:FR&key=${process.env.REACT_APP_API_KEY_MAPS_JS}`
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${body.completeAdressFormated}&components=postal_code:${body.zipCode}|country:FR&key=${process.env.REACT_APP_API_KEY_MAPS_JS}`;
    const url2 = `${base_url}/api/places`;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${body.token}`,
      "websiteid": body.infos.websiteId
    }
    try {
      const response = await axios.get(url);
      const location = response.data.results[0].geometry.location;
      //console.log(location)
      body.infos["lat"] = location.lat;
      body.infos["lng"] = location.lng;
      console.log("body.infos", body.infos)
      const res2 = await axios.post(url2, body.infos, {headers});
      return res2.data;
    } catch (error) {
      return {status: "error", message : error.message};
    }
  }
);

export const getAllPlaces = createAsyncThunk(
  "places/getAllPlaces",
  async (body) => {
    const { websiteId, token } = body;
    const url = `${base_url}/api/places/${websiteId}`;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
      "websiteid": websiteId
    }
    try {
      const allPlacesFromApi = await axios.get(url, {headers});
      return allPlacesFromApi.data;
    } catch (error) {
      return false;
    }
  }
);

export const getGooglePlacePicture = createAsyncThunk(
    "places/getGooglePlacePicture",
    async (body) => {
        //console.log(body.completeAdressFormated, body.zipCode)
        const url2 = `https://maps.googleapis.com/maps/api/geocode/json?address=${body.completeAdressFormated}&components=postal_code:${body.zipCode}|country:FR&key=${process.env.REACT_APP_API_KEY_MAPS_JS}`

        try {
            const getGooglePlaceId = await axios.get(url2);
            const googlePlaceId = getGooglePlaceId.data.results[0].place_id;
            // const service = new window.google.maps.places.PlacesService();
            //  const request = {
            //      placeId: googlePlaceId,
            //      fields: ["name", "formatted_address", "place_id", "geometry"],
            //  };
            // service.getDetails(request, (place, status) => {
            //      console.log(status)
            // }).then((value) => { console.log(value) });
            
            // const map = new window.google.maps.Map(
            //     document.getElementById("map"),
            // );
            // const request = {
            //     placeId: "ChIJN1t_tDeuEmsRUsoyG83frY4",
            //     fields: ["name", "formatted_address", "place_id", "geometry"],
            // };
            // const service = new window.google.maps.places.PlacesService();

            // service.getDetails(request, (place, status) => {
            //     console.log(status)
            // });


        } catch (error) {
            return false;
        }
    }
);

export const getPlaceToBeInDeleteList = createAsyncThunk (
  'places/getPlaceToBeInDeleteList',
  async (body) => {
    try {
      const { websiteId, token} = body;
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        "websiteid": websiteId
      }
      const res = await axios.get(`${base_url}/api/places/deleteList/${websiteId}`, {headers});
      console.log(res.data);
      return res.data;
    } catch (error) {
      return false;
    }
  }
)
export const deleteOnePlace = createAsyncThunk (
  'places/deleteOnePlace',
  async (body) => {
    const placeId = parseInt(body.placeId);
    const {websiteId, token} = body;
    try {
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        "websiteid": websiteId
      }
      const res = await axios.delete(`${base_url}/api/places/${placeId}`, {headers});
      console.log(res.data);
      return res.data
    } catch (error) {
     return false 
    }
  }
)
export default places.reducer;
