import React, {useState, useEffect} from 'react'
import {Box, Snackbar, Alert} from "@mui/material";

const AlertMessage = ({ setAlert, alert }) => {
    
    const [width, setWidth] = useState(window.innerWidth);
    const isMobile = width <= 767;

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAlert(false);
    };

return (
    <Box>
        <Snackbar
            open={alert.display}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={!isMobile ? { vertical: "bottom", horizontal: "center" } : { vertical: "top", horizontal: "center" }}
        >
            <Alert
                onClose={handleClose}
                severity={alert.type}
                sx={{ width: "100%" }}
            >
                {alert.message}
            </Alert>
        </Snackbar>
    </Box>
)
};
export default AlertMessage;