import React, { useState, useEffect } from "react";
// import img from 'src/assets/svg/agenda.svg';
//import img from "../../assets/img/agenda2.jpg";
import PropTypes from "prop-types";
import "./AgendaPageGuest.scss";
import EventGuest from "./EventGuest.js";
import PageTitle from "../../PageTitle/PageTitle";
import { useSelector, useDispatch } from "react-redux";
import { getAllEvents } from "../../../reducer/events";
import {setIsLoading} from "../../../reducer/websites.js";
import HeaderGuest from '../../pagesGuest/HeaderGuest/HeaderGuest';

//TODO pickup date https://mui.com/x/react-date-pickers/date-picker/
const AgendaPageGuest = () => {
  const dispatch = useDispatch();
  const events = useSelector((state) => state.events);
  const websites = useSelector((state) => state.websites);
  const token = useSelector((state) => state.users.token);
  const [imageUploaded, setImageUploaded] = useState(websites.websiteInfos.agendaImage);
  const [alert, setAlert] = useState({
    display: false,
    message: null,
    type: null,
  });
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      dispatch(setIsLoading(false));
      const body = {
        websiteId: websites.currentWebsiteId,
        token
      }
      await dispatch(getAllEvents(body));
    };
    if(token!==null){
      fetchEvents();
    }
  }, [alert, websites.currentWebsiteId]);


  return (
    <div className="agenda">
      <HeaderGuest />
      <PageTitle name="Agenda" />
      <div className="agenda__container">
        <div className="agenda__container-events">
          {events.length > 0 &&
            events.map((event, index) => (
              <EventGuest
                key={index}
                event={event}
              />
            )) }
        </div>
        {!isMobile &&
        <div className="agenda__container-img">
          <img
            className="agenda__img"
            src={`${imageUploaded}?id=${Date.now()}`}
            alt="wedding"
          />
        </div>
        }
      </div>
    </div>
  );
};

AgendaPageGuest.propTypes = {};

export default AgendaPageGuest;
