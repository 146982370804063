export const formatAdress = (streetName) => {
    const arrStreetName = streetName.trim().split(" ");
    let newStr = "";
    for (let i = 0; i < arrStreetName.length; i++) {
        newStr += ` ${arrStreetName[i]} +`;
    }
    const withoutLastPlus = newStr.split(" ").slice(0, -1);
    withoutLastPlus.shift();
    const steetNameFormated = withoutLastPlus.join(" ");
    const completeAdressFormated = `${steetNameFormated}`;
    return completeAdressFormated;
}