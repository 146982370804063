import React, { useEffect, useState } from "react";
import HeaderGuest from "../HeaderGuest/HeaderGuest";
import PageTitle from "../../PageTitle/PageTitle.js";
import {
  Paper,
  Box,
  Button,
  Stack,
  TextField,
  IconButton,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useSelector, useDispatch } from "react-redux";
import { getAllMessage, deleteOneMessage, addOneMessage } from "../../../reducer/messages";
import moment from "moment";
import { momentLocaleConfig } from "../../../utils/momentLocalConfig.js";
import { getAuth } from "firebase/auth";
import { getConnectedGuestId } from "../../../reducer/guests";
import DeleteIcon from "@mui/icons-material/Delete";

const LivreOrGuest = () => {
  moment.updateLocale("fr", momentLocaleConfig);
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const emailCurrentUser = currentUser.email;
  const websites = useSelector((state) => state.websites);
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.messages);
  const [guestId, setGuestId] = useState(null);
  const token = useSelector((state) => state.users.token);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const [signature, setSignature] = useState({
    errorText: "",
    value: null,
  });
  const [message, setMessage] = useState({
    errorText: "",
    value: null,
  });
  useEffect(() => {
    const fetchAllMessage = async () => {
      const body = {
        websiteId: websites.currentWebsiteId,
        token
      }
      await dispatch(getAllMessage(body));
    };
    const fetchGuestId = async () => {
      const body = {
        email: emailCurrentUser,
        websiteId: websites.currentWebsiteId,
      };
      const res = await dispatch(getConnectedGuestId(body));
      console.log("res.padding", res.payload);
      setGuestId(res.payload.guestId);
    };
    if (token !== null) {
      fetchGuestId();
      fetchAllMessage();
    }
  }, []);

  const handleSubmitMessage = async (e) => {
    if (signature.value === null || signature.value.length === 0) {
      setSignature({
        errorText: "Indiquez la signature de votre message",
        value: null,
      });
      return;
    }
    if (message.value === null || message.value.length === 0) {
      setMessage({ errorText: "indiquez votre message", value: null });
      return;
    }

    const body = {
      websiteId: websites.currentWebsiteId,
      signature: signature.value,
      message: message.value,
      email: emailCurrentUser,
      token
    };

    const response = await dispatch(addOneMessage(body));
    if (response.payload.status === 200) {
      const body = {
        websiteId: websites.currentWebsiteId,
        token
      }
      await dispatch(getAllMessage(body));
    }
  };

  const handleDeleteMessage = async (e) => {
    const body = {
      messageId: e.currentTarget.id,
      token,
      websiteId: websites.currentWebsiteId
    }
    const res = await dispatch(deleteOneMessage(body));
    if(res.payload.status===200){
      const body1= {
        token,
        websiteId: websites.currentWebsiteId
      }
      await dispatch(getAllMessage(body1));
    }
  }

  return (
    <div>
      <HeaderGuest />
      <PageTitle name="Livre d'Or" />
      <Box sx={!isMobile ? { padding: "2rem" } : { padding: "1rem" }}>
        <Paper sx={!isMobile ? {padding: "2rem" } : {padding: "1rem" }}>
          <Stack direction="column" spacing={2}>
            <TextField
              variant="standard"
              sx={!isMobile ? { width: "40%", pt: 2, mt: 0 } : { width: "100%", pt: 2, mt: 0 }}
              label={"Signature"}
              inputProps={{ maxLength: 44 }}
              helperText={signature.errorText}
              onChange={(e) =>
                setSignature({ errorText: "", value: e.target.value })
              }
              error={signature.errorText.length === 0 ? false : true}
            />
            <TextField
              sx={!isMobile ? { width: "90%", pt: 2, mt: 0 } : { width: "100%", pt: 2, mt: 0 }}
              label="Mon message"
              multiline
              variant="standard"
              helperText={message.errorText}
              onChange={(e) =>
                setMessage({ errorText: "", value: e.target.value })
              }
              error={message.errorText.length === 0 ? false : true}
            />
          </Stack>
          <Button
            sx={{ mt: 4 }}
            startIcon={<SendIcon />}
            variant="contained"
            onClick={handleSubmitMessage}
          >
            Envoyer mon message
          </Button>
        </Paper>
      </Box>
      {messages.messageInfos !== null &&
        messages.messageInfos.map((message) => {
          return (
            <Box
              key={message.messageId}
              sx={!isMobile ? { pl: "2rem", pr: "2rem", pt: "0.5rem" } : { pl: "1rem", pr: "1rem", pt: "0.5rem" }}
            >
              <Paper sx={!isMobile ? { padding: "2rem" } : { padding: "1rem" }}>
                <Stack direction="row" justifyContent="space-between">
                  <Stack>
                    <Stack direction="row" spacing={1}>
                      <strong>{message.signature}</strong>
                      <i> - {moment(message.created_at).format("LLL")}</i>
                  </Stack>
                    <p>{message.content}</p>
                  </Stack>
                  <Stack>
                    {message.guest_id === guestId && (
                      <IconButton onClick={handleDeleteMessage} id={message.messageId}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Stack>
              </Stack>
                
              </Paper>
            </Box>
          );
        })}
    </div>
  );
};
export default LivreOrGuest;
