import React, {useState, useEffect } from "react";
import { Modal, Box, Paper, Stack } from "@mui/material";
import {Chart,PieSeries, Title, Legend} from '@devexpress/dx-react-chart-material-ui';
import { useSelector, useDispatch } from "react-redux";
import { getOrganizerFriends, getGroupData } from '../../../reducer/guests';
import { Animation } from '@devexpress/dx-react-chart';
import { Palette } from '@devexpress/dx-react-chart';

const GuestStatModal = ({ isOpen, setIsOpen }) => {
    const organizers = useSelector((state) => state.organizers);
    const websites = useSelector((state) => state.websites);
    
    const dispatch = useDispatch();
    const closeModal = () => {
        setIsOpen(false);
    };
    const [groupData, setGroupData] = useState([]);
    

    useEffect(() => {
        const fetchGroupData = async () => {
            const body = {
                websiteId: websites.currentWebsiteId,
                organizerId: organizers.currentOrganizerId,
                organizerName: organizers.currentOrganizerInfos.firstname,
                organizerPartnerName: organizers.currentOrganizerInfos.firstnamePartner
            }
            const countGroup = await dispatch(getGroupData(body));
            console.log("countGroup", countGroup.payload);
            setGroupData(countGroup.payload.count)
        }
        fetchGroupData();
        // const fetchOrganizerFriends = async () => {
        //     await dispatch(getOrganizerFriends(websites.currentWebsiteId, organizers.currentOrganizerId));
        // };
        // fetchOrganizerFriends();
    }, [])
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "60%",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
    };
    const colors = ["#E6E6E6", websites.websiteInfos.primaryColor, websites.websiteInfos.secondaryColor, websites.websiteInfos.thirdColor]

    return (
        <Modal
            open={isOpen}
            onClose={(e, reason) => {
                if (reason !== "backdropClick") {
                    closeModal();
                }
            }}
        >
            <Box sx={style}>
                    <Stack spacing={2}>
                        <Box>
                        <Chart width= '30%' data={groupData}>
                                <Title text="Groupe d'invités"/>
                                <Legend />
                                <Palette scheme={colors} />

                                <PieSeries
                                    valueField="area"
                                    argumentField="Groupe"
                                    />
                                <Animation />
                            </Chart>
                        </Box>
                        <Box>
                        {/* <Chart width='30%' data={groupData}>
                                <Palette scheme={colors} />
                                <PieSeries
                                    valueField="area"
                                    argumentField="Groupe"
                                />
                                <Title text="Groupe d'invités" />
                                <Animation />
                                <Legend />
                            </Chart> */}
                        </Box>
                    </Stack>
            </Box>
            
        </Modal>
    );
};
export default GuestStatModal;
