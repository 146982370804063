import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  displayGuestForm,
  setDisabledBtnAddGuest,
} from "../../../reducer/displayGuestPage.js";
import { getAllGuestsNoLimit } from "../../../reducer/guests.js";
import GuestTable from "./GuestTable";
import { Button, Stack, Grid, Paper, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddGuestForm from "./AddGuestForm.js";
import "./GuestPage.scss";
import AlertMessage from "../../AlertMessage/AlertMessage.js";
import PageTitle from "../../PageTitle/PageTitle.js";
import { setIsAnyGuestDeleteAction } from "../../../reducer/guests.js";
import confirmed from "../../../assets/svg/confirmed.svg";
import waiting from "../../../assets/svg/waiting.svg";
import notComming from "../../../assets/svg/notComming.svg";
import child from "../../../assets/svg/child.svg";
import UploadIcon from "@mui/icons-material/Upload";
import DisplayCsvUploadModal from "./DisplayCsvUploadModal.js";
import Header from "../Header/Header";
import { getTotal, getTotalNumber } from "../../../utils/guest";
import GuestStatModal from "./GuestStatModal";

//TODO import/export excel, import csv; ajouter des aggrégations;
const GuestPage = () => {
  const dispatch = useDispatch();
  const [displayCsvUploadModal, setDisplayCsvUploadModal] = useState(false);
  const [dispayStatModal, setDispayStatModal] = useState(false);
  const organizers = useSelector((state) => state.organizers);
  const websites = useSelector((state) => state.websites);
  const token = useSelector((state) => state.users.token);
  const [alert, setAlert] = useState({
    display: false,
    message: null,
    type: null,
  });
  const [totalWillBePresent, setTotalWillBePresent] = useState(0);
  const [totalWillBeNotPresent, setTotalWillBeNotPresent] = useState(0);
  const [totalWaitingResponse, setTotalWaitingResponse] = useState(0);
  const [totalNumberOfChildren, setTotalNumberOfChildren] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);


  const handleOnClickAddGuestBtnOpenForm = () => {
    dispatch(displayGuestForm(true));
    dispatch(setDisabledBtnAddGuest(true));
  };
  const guests = useSelector(
    (state) => state.guests
  );

  const displayAddGuestForm = useSelector(
    (state) => state.displayGuestPage.displayForm
  );
  const disabledBtnAddGuest = useSelector(
    (state) => state.displayGuestPage.disabledBtnAddGuest
  );
  //au moment de delete un guest on actualise la page pour fetch allguests pour pouvoir recupérer les bonnes stats
  useEffect(() => {
    if (token !== null) {
    fetchAllGuests();
  }
  }, [guests.isAnyGuestDeleteAction, guests.isAnyGuestAddingAction]);

  const fetchAllGuests = async () => {
    if (
      organizers.currentOrganizerId !== null &&
      websites.currentWebsiteId !== null
    ) {
      const response = await dispatch(
        getAllGuestsNoLimit({
          organizerId: organizers.currentOrganizerId,
          websiteId: websites.currentWebsiteId,
          token
        })
      );

      const totalWillBePresent = getTotal(
        response.payload,
        "willBePresent",
        "Oui"
      );
      const totalPartnerWillBePresent = getTotal(
        response.payload,
        "partnerWillBePresent",
        "Oui"
      );
      const calculTotaNumberOfChildren = getTotalNumber(
        response.payload,
        "numberOfChildren",
      )
      const totalWillBeNotPresent = getTotal(
        response.payload,
        "willBePresent",
        "Non"
      );
      const totalPartnerWillBeNotPresent = getTotal(
        response.payload,
        "partnerWillBePresent",
        "Non"
      );
      const totalWaitingResponseWillBePresent = getTotal(
        response.payload,
        "willBePresent",
        "En attente"
      );
      const totalWaitingPartnerWillBePresent = getTotal(
        response.payload,
        "partnerWillBePresent",
        "En attente"
      );
      setTotalNumberOfChildren(calculTotaNumberOfChildren);
      setTotalWillBeNotPresent(
        totalWillBeNotPresent + totalPartnerWillBeNotPresent
      );
      setTotalWillBePresent(totalWillBePresent + totalPartnerWillBePresent);
      setTotalWaitingResponse(
        totalWaitingResponseWillBePresent + totalWaitingPartnerWillBePresent
      );
      dispatch(setIsAnyGuestDeleteAction(false));
    }
  };

  return (
    <div>
      <Header />
      <PageTitle name="Mes Invités" />
      {alert.display && <AlertMessage setAlert={setAlert} alert={alert} />}
      <Box sx={!isMobile ? { pb: 2 } : {pb: 4}}>
        <Stack direction={!isMobile ? "row": "column"} sx={{ justifyContent: "space-between" }}>
          <Paper
            sx={!isMobile ? { mb: 2, ml: 2, mr: 2, p: 2, width: "fit-content" } : { mb: 1, ml: 1, mr: 1, p: 1, width: "fit-content" }}
            className="guestPage__clic"
            onClick={() => setDispayStatModal(true)}
          >
            <img className="guestPage__icon-stat" alt="icone pouce levé" src={confirmed} />{" "}
            {`Confirmés (${totalWillBePresent})`}{" "}
            <img className="guestPage__icon-stat" alt="icone sablier" src={waiting} />{" "}
            {`En attente (${totalWaitingResponse})`}{" "}
            <img className="guestPage__icon-stat" alt="icone pouce baissé" src={notComming} />{" "}
            {`Refus(${totalWillBeNotPresent})`}
            <img className="guestPage__icon-stat" alt="icone children" src={child} />{" "}
            {`Enfants(${totalNumberOfChildren})`}
          </Paper>
          {dispayStatModal && (
            <GuestStatModal
              isOpen={dispayStatModal}
              setIsOpen={setDispayStatModal}
            />
          )}
          <Button
            startIcon={<UploadIcon />}
            sx={!isMobile ? { ml: 2, mr: 2, width: "fit-content", height: "2.5rem" } : { ml: 1, mr: 1, width: "60%", height: "2.5rem", mb:2, mt:1 }}
            variant="contained"
            component="label"
            onClick={() => setDisplayCsvUploadModal(true)}
          >
            Importer liste CSV
          </Button>
          {displayCsvUploadModal && (
            <DisplayCsvUploadModal
              isOpen={displayCsvUploadModal}
              setIsOpen={setDisplayCsvUploadModal}
              setAlert={setAlert}
            />
          )}
        </Stack>
        <GuestTable setAlert={setAlert} />
        <Stack sx={!isMobile ? { width: "100%", pl: 2, pr: 2 } : { width: "100%", pl: 1, pr: 1, }} spacing={2}>
          <Grid container>
            <Button
              startIcon={<AddIcon />}
              onClick={handleOnClickAddGuestBtnOpenForm}
              variant="contained"
              disabled={disabledBtnAddGuest}
            >
              Ajouter un invité
            </Button>
          </Grid>
          {displayAddGuestForm && (
            <AddGuestForm setAlert={setAlert} alert={alert} />
          )}
        </Stack>
      </Box>
    </div>
  );
};
export default GuestPage;
