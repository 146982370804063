import React, {useEffect, useState} from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Box from '@mui/material/Box';
import './LocationPin.scss';
import { useDispatch } from "react-redux";
import { getGooglePlacePicture } from "../../../reducer/places.js";
import { formatAdress } from '../../../utils/map.js';
import { placeIcons, eventIcons } from "../../../reducer/icons/icons";

//TODO récuperer le join mergé pour affichez tous les events d'un seul lieu

const LocationPin = ({place}) => {
    const [displayPinInfo, setDisplayPinInfo] = useState(false);
    const dispatch = useDispatch();
    
    useEffect(()=>{
        const completeAdressFormated = formatAdress(place.streetName);
        const zipCode = place.zipCode.slice(0, 5);
        const body = {
            completeAdressFormated, zipCode
        }
        dispatch(getGooglePlacePicture(body))
        // console.log("place", place)
    }, []);
return (
    <div>
        <LocationOnIcon className="location-pin" style={{ color: 'red' }} onMouseOver={() => {return setDisplayPinInfo(true)}} onMouseLeave={() => setDisplayPinInfo(false)} />
        {displayPinInfo && 
            <div className="pin-info__container">
                <img className="icon" src={eventIcons[place.icon]} alt={place.icon} />
                <img className="icon" src={placeIcons[place.addedIcon]} alt={place.addedIcon} />
                <h4><u>{place.title}</u></h4>
                <p>{place.streetName + ', ' + place.zipCode + ', ' + place.city}</p>
        </div>
        }
    </div>
    
)
};
export default LocationPin;