import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

const Loader = () => {

return (
    <div style={{display: 'flex', justifyContent: 'center', paddingTop:'25%'}}>
        <CircularProgress disableShrink size='8rem'/>
    </div>
    
)
};
export default Loader;
