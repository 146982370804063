import firebase from "firebase/compat/app";
import { getFirestore, collection, addDoc, where, query, getDocs } from "firebase/firestore"
import "firebase/compat/auth";
import {capitalizeFirstLetter} from '../utils/user';
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY_FIREBASE,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);
const db = getFirestore();
export const auth = firebase.auth();
export default firebase;

export const registerWithEmailAndPassword = async (firstname, lastname, email, password) => {
      
    try {
        const res = await auth.createUserWithEmailAndPassword(email, password);
        const user = res.user;
        await user.updateProfile({displayName: capitalizeFirstLetter(firstname) + "+" + capitalizeFirstLetter(lastname)})
        const ress = await user.getIdToken(/*force refresh*/true);
       // console.log("ress", ress);
        // await addDoc(collection(db, "organizers"), {
        //     uid: user.uid,
        //     firstname,
        //     lastname,
        //     authProvider: "local",
        //     email,
        // });
        //console.log("res._tokenResponse.refreshToken", ress)
        //sessionStorage.setItem('Auth Token', res._tokenResponse.refreshToken)

        return {status: 200, uid: user.uid}
         //return {status: 200}

    } catch (err) {
        switch (err.code) {
            case "auth/Invalid-email":
                return { error: "Email invalid" };
            case "auth/email-already-in-use":
                return { error: "Cette adresse mail a déjà été utilisée pour une inscription" };
            case "auth/weak-password":
                return { error: "le mot de passe doit contenir au oins 6 caractères"}
            default:
                return { error: err.message };
        }
    }
};

export const signInWithEmailAndPassword = async (email, password) => {
    try {
        const res = await auth.signInWithEmailAndPassword(email, password);
        const user = res.user;
        const ress = await user.getIdToken(/*force refresh*/true);
        console.log("user", user)
        //console.log("uid: user.uid", user.uid);
        return {status: 200,  uid: user.uid, displayName: user.displayName};
    } catch (err) {
        switch (err.code) {
            case "auth/invalid-email":
                return { error: "Le mail est invalide" };
            case "auth/user-disabled":
                return { error: "L'email a été désactivé" };
            case "auth/user-not-found":
                return { error: "Vous n'êtes pas inscris" }
            case "auth/wrong-password":
                return { error: "Le mot de passe est incorrect"}
            default:
                return { error: err.message };
        }
    }
};


export const sendPasswordReset = async (email) => {
    try {
        await auth.sendPasswordResetEmail(email, {
            url: 'http://localhost:3000',
        });
        return {status: 200}
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};