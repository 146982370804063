import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSelectedGuests,
  getAllGuests,
  setIsAnyGuestDeleteAction,
} from "../../../reducer/guests.js";

export default function AlertDialog(props) {
  const dispatch = useDispatch();
  const organizers = useSelector((state) => state.organizers);
  const websites = useSelector((state) => state.websites);
  const token = useSelector((state) => state.users.token);
  const { open, setOpen, selectedIdGuests, page, rowsPerPage, setAlert } = props;
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = async () => {
    const body = {
      websiteId: websites.currentWebsiteId,
      organizerId: organizers.currentOrganizerId,
      token,
      selectedIdGuests
    }
    const result = await dispatch(deleteSelectedGuests(body));
    if (result.payload.status === 200) {
      setAlert({
        display: true,
        message: result.payload.message,
        type: "success",
      });
      const param = {
        organizerId: organizers.currentOrganizerId,
        websiteId: websites.currentWebsiteId,
        page: page + 1,
        limit: rowsPerPage,
        token
      };
      dispatch(getAllGuests(param));
      dispatch(setIsAnyGuestDeleteAction(true));
      setOpen(false);
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Attention action irreversible !"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Êtes vous sûr de vouloir supprimer{" "}
            {selectedIdGuests.length === 1
              ? "cet invité"
              : `ces ${selectedIdGuests.length} invités`}{" "}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleDelete} autoFocus>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
