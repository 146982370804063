import React, { useState } from "react";
import { eventIcons } from "../../../reducer/icons/icons";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { deleteOneEvent, getAllEvents } from "../../../reducer/events";
import { Tooltip, IconButton } from "@mui/material";
import AlertMessage from "../../AlertMessage/AlertMessage.js";
import {momentLocaleConfig} from "../../../utils/momentLocalConfig.js";
import moment from "moment";
 
const Event = ({ event, setDisplayUpdateEventModal, setEventIdToUpdate }) => {
  const dispatch = useDispatch();
  const websites = useSelector((state) => state.websites);
  const [alert, setAlert] = useState({
    display: false,
    message: null,
    type: null,
  });
  const token = useSelector((state) => state.users.token);

  const handleDeleteEvent = async (e) => {
    const body = {
      eventId: event.eventId,
      websiteId: websites.currentWebsiteId,
      token
    }
    const res = await dispatch(deleteOneEvent(body));
    if (res.payload.status === 500 && res.payload.message) {
      setAlert({ display: true, message: res.payload.message, type: "error" });
    }
    if (res.payload.status === 200 && res.payload.message) {
      setAlert({
        display: true,
        message: res.payload.message,
        type: "success",
      });
    }
   
    dispatch(getAllEvents(body));
  };

  const handleUpdateEvent = async (e) => {
    setDisplayUpdateEventModal(true);
    setEventIdToUpdate(event.eventId);
  };


  let date = event.date;
  moment.updateLocale('fr', momentLocaleConfig)
  date = moment(date).format('LL');
  
  return (
    <div className="agenda__container-event">
      {alert.display && (
        <AlertMessage setAlert={setAlert} alert={alert} />
      )}
      <div className="agenda__container-event-icon-text">
        <img
          className="agenda__event-icon"
          alt={event.icon}
          src={eventIcons[event.icon]}
        />
        <div className="agenda__event-container-text">
          <h5 className="agenda__event-title">
            <u className="agenda__event-text-important">{event.title}</u>{" "}
            <span className="agenda__event-text-not-important">
               : Rendez-vous le
            </span>{" "}
            <b className="agenda__event-text-important">{date}</b>{" "}
            <span className="agenda__event-text-not-important">à</span>{" "}
            <b className="agenda__event-text-important">{event.schedule}</b> 
            
          </h5>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="agenda__event-address"
            href="https://goo.gl/maps/Ajmav14GCYdAVX5b7"
          >
            {event.streetName} / {event.city} , France
          </a>
        </div>
      </div>
      <div className="agenda__event-btn-icon">
        <Tooltip title="Edit">
          <IconButton onClick={handleUpdateEvent}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton onClick={handleDeleteEvent}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};
export default Event;
