import React, { useState } from "react";
import { eventIcons } from "../../../reducer/icons/icons";
import {momentLocaleConfig} from "../../../utils/momentLocalConfig.js";
import moment from "moment";
 
const EventGuest = ({ event }) => {
  let date = event.date;
  moment.updateLocale('fr', momentLocaleConfig)
  date = moment(date).format('LL');
  
  return (
    <div className="agenda__container-event">
      
      <div className="agenda__container-event-icon-text">
        <img
          className="agenda__event-icon"
          alt={event.icon}
          src={eventIcons[event.icon]}
        />
        <div className="agenda__event-container-text">
          <h5 className="agenda__event-title">
            <u className="agenda__event-text-important">{event.title}</u>{" "}
            <span className="agenda__event-text-not-important">
               : Rendez-vous le
            </span>{" "}
            <b className="agenda__event-text-important">{date}</b>{" "}
            <span className="agenda__event-text-not-important">à</span>{" "}
            <b className="agenda__event-text-important">{event.schedule}</b> 
            
          </h5>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="agenda__event-address"
            href="https://goo.gl/maps/Ajmav14GCYdAVX5b7"
          >
            {event.streetName} / {event.city} , France
          </a>
        </div>
      </div>
      
    </div>
  );
};
export default EventGuest;
