import React, { Fragment, useState, useEffect } from "react";
import {
  Modal,
  Box,
  Stack,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  FormHelperText,
} from "@mui/material";
import "./AddEventFormModal.scss";
import { eventIcons, eventIconsInfos } from "../../../reducer/icons/icons";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { createOrUpdateOneEvent } from "../../../reducer/events.js";
import { addOnePlaceIfNotExist } from "../../../reducer/places.js";
import { formatAdress } from "../../../utils/map.js";
import PropTypes from "prop-types";

//TODO update des icons

const AddEventFormModal = ({ isOpen, setIsOpen, events, eventIdToUpdate, setAlert }) => {
  const token = useSelector((state) => state.users.token);
  const [nameEvent, setNameEvent] = useState({
    errorText: "",
    value: eventIdToUpdate
      ? events.filter((event) => event.eventId === eventIdToUpdate)[0].title
      : null,
  });
  const [date, setDateEvent] = useState({
    errorText: "",
    value: eventIdToUpdate
      ? events
          .filter((event) => event.eventId === eventIdToUpdate)[0]
          .date.slice(0, 10)
      : null,
  });
  const [schedule, setSchedule] = useState({
    errorText: "",
    value: eventIdToUpdate
      ? events.filter((event) => event.eventId === eventIdToUpdate)[0].schedule
      : null,
  });
 
  const [streetName, setStreetName] = useState({
    errorText: "",
    value: eventIdToUpdate
      ? events.filter((event) => event.eventId === eventIdToUpdate)[0]
          .streetName
      : null,
  });
  const [zipCode, setZipCode] = useState({
    errorText: "",
    value: eventIdToUpdate
      ? events.filter((event) => event.eventId === eventIdToUpdate)[0].zipCode
      : null,
  });
  const [city, setCity] = useState({
    errorText: "",
    value: eventIdToUpdate
      ? events.filter((event) => event.eventId === eventIdToUpdate)[0].city
      : null,
  });
  const [country, setCountry] = useState({
    errorText: "",
    value: eventIdToUpdate
      ? events.filter((event) => event.eventId === eventIdToUpdate)[0].country
      : null,
  });
  const [icon, setIcon] = useState({
    errorText: "",
    value: eventIdToUpdate
    ? events.filter((event) => event.eventId === eventIdToUpdate)[0].icon
    : null,
  });
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  const dispatch = useDispatch();
  const websites = useSelector((state) => state.websites);

  const handleSubmitAddEvent = async () => {
    
    if (nameEvent.value === null || nameEvent.value.length === 0) {
      setNameEvent({ errorText: "Indiquez le titre de l'évenement", value: null });
      return;
    }
    if (icon.value === null || icon.value.length === 0) {
      setIcon({ errorText: "Selectionner une icon", value: null });
      return;
    }
    if (date.value === null || date.value.length === 0) {
      setDateEvent({ errorText: "Indiquez la date de l'évenement", value: null });
      return;
    }
    if (schedule.value === null || schedule.value.length === 0) {
      setSchedule({ errorText: "Indiquez l'horaire de l'évenement", value: null });
      return;
    }
    if (streetName.value === null || streetName.value.length === 0) {
      setStreetName({ errorText: "Indiquez l'adresse de l'évenement", value: null });
      return;
    }
    if (zipCode.value === null || zipCode.value.length === 0) {
      setZipCode({ errorText: "Indiquez le code postal de l'évenement", value: null });
      return;
    }
    if (city.value === null || city.value.length === 0) {
      setCity({ errorText: "Indiquez la ville de l'évenement", value: null });
      return;
    }
    if (country.value === null || country.value.length === 0) {
      setCountry({ errorText: "Indiquez le pays de l'évenement", value: null });
      return;
    }
    
    const completeAdressFormated = formatAdress(
      streetName.value
      );
      
      const body = {
        completeAdressFormated,
        zipCode: zipCode.value,
        infos: {
          websiteId: websites.currentWebsiteId, 
          name: "",
          streetName: streetName.value,
          zipCode: zipCode.value,
          city: city.value,
          addedIcon: "",
        },
        token
      };
    const response = await dispatch( addOnePlaceIfNotExist(body));
      if (response.payload.status === 200) {
        const placeId = response.payload.insertId;
        console.log("icon.value", icon.value);
        const res =  await dispatch(
           createOrUpdateOneEvent({
            eventId: eventIdToUpdate ? eventIdToUpdate : null,
            title: nameEvent.value,
            date: date.value,
            schedule: schedule.value,
            icon: icon.value,
            place_id: placeId,
            website_id: websites.currentWebsiteId,
            token
          })
          );
          if(res.payload.status === 200){
            setIsOpen(false);
            setAlert({
              display: true,
              message: `${eventIdToUpdate ? 'Votre événement a bien été modifié' : 'Votre événement a bien été ajouté'}`,
              type: "success",
            })
          }else{
            setAlert({
              display: true,
              message: res.payload.message,
              type: "error",
            })
          }
      } else if (response.payload.status === 'error'){
        setAlert({
          display: true,
          message: "Le lieu indiqué sur votre événement n'est pas reconnu, veuillez vérifier l'adresse",
          type: "error",
        }) 
      }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const style = !isMobile ? {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  } : {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "92%",
      bgcolor: "background.paper",
      boxShadow: 24,
      pl: 1,
      pr: 1,
      pt: 2,
      pb: 2
  };

  return (
    <Modal
      open={isOpen}
      onClose={(e, reason) => {
        if (reason !== "backdropClick") {
          closeModal();
        }
      }}
    >
      <Box sx={style}>
        <form>
          {/* --------------QUOI--------------------- */}
          <hr className="addEventForm__separator" data-before="Quoi ?" />
          <Stack direction="column" spacing={2}>
            <TextField
              required
              variant="standard"
              label={"Titre"}
              helperText={nameEvent.errorText}
              onChange={(e) =>
                setNameEvent({ errorText: "", value: e.target.value })
              }
              defaultValue={
                eventIdToUpdate
                  ? events.filter(
                      (event) => event.eventId === eventIdToUpdate
                    )[0].title
                  : ""
              }
              sx={!isMobile ? { width: "50%", pb: 2, mt: 0 } : { width: "100%", pb: 2, mt: 0 }}
              error={nameEvent.errorText.length === 0 ? false : true}
            />
            <RadioGroup
              required
              // helperText={icon.errorText}
              // error={icon.errorText.length === 0 ? false : true}
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={
                eventIdToUpdate
                  ? events.filter(
                      (event) => event.eventId === eventIdToUpdate
                    )[0].icon
                  : ""
              }
              name="radio-buttons-group"
              onChange={(e) =>
                setIcon({ errorText: "", value: e.target.value })
              }
              row
              sx={{ width: "100%" }}
            >
              {eventIconsInfos.map((icon, index) => {
                return (
                  <div key={index}>
                    <FormControlLabel
                      control={<Radio />}
                      label={
                        <Fragment>
                          <img
                            src={eventIcons[icon.name]}
                            alt={icon.label}
                            // key={id}
                            width="25rem"
                            height="auto"
                            style={{ marginRight: "5px" }}
                          />
                          {icon.label}
                        </Fragment>
                      }
                      value={icon.name}
                    />
                  </div>
                );
              })}
            </RadioGroup>
            <FormHelperText sx={{ color: "#de9999" }}>
              {icon.errorText}
            </FormHelperText>
          </Stack>

          {/* --------------QUAND--------------------- */}
          <hr className="addEventForm__separator" data-before="Quand ?" />
          <Stack direction="row" spacing={2} sx={{ mt: 4, pb: 4 }}>
            <TextField
              required
              helperText={date.errorText}
              sx={{ width: "50%" }}
              id="date"
              onChange={(e) =>
                setDateEvent({ errorText: "", value: e.target.value })
              }
              error={date.errorText.length === 0 ? false : true}
              type="date"
              defaultValue={
                eventIdToUpdate
                  ? events
                      .filter((event) => event.eventId === eventIdToUpdate)[0]
                      .date.slice(0, 10)
                  : ""
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              required
              helperText={schedule.errorText}
              sx={{ width: "50%" }}
              onChange={(e) =>
                setSchedule({ errorText: "", value: e.target.value })
              }
              id="time"
              type="time"
              error={schedule.errorText.length === 0 ? false : true}
              defaultValue={
                eventIdToUpdate
                  ? events.filter(
                      (event) => event.eventId === eventIdToUpdate
                    )[0].schedule
                  : ""
              }
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </Stack>

          {/* --------------Où--------------------- */}
          <hr className="addEventForm__separator" data-before="Où ?" />
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <TextField
              required
              variant="standard"
              helperText={streetName.errorText}
              onChange={(e) =>
                setStreetName({ errorText: "", value: e.target.value })
              }
              label={"Adresse"}
              sx={{ width: "100%" }}
              error={streetName.errorText.length === 0 ? false : true}
              defaultValue={
                eventIdToUpdate
                  ? events.filter(
                      (event) => event.eventId === eventIdToUpdate
                    )[0].streetName
                  : ""
              }
            />
          </Stack>

          <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
            <TextField
              required
              helperText={zipCode.errorText}
              variant="standard"
              onChange={(e) =>
                setZipCode({ errorText: "", value: e.target.value })
              }
              label={"Code Postal"}
              error={zipCode.errorText.length === 0 ? false : true}
              sx={{ width: "20%", pb: 1 }}
              defaultValue={
                eventIdToUpdate
                  ? events.filter(
                      (event) => event.eventId === eventIdToUpdate
                    )[0].zipCode
                  : ""
              }
              inputProps={{ maxLength: 12 }}
            />
            <TextField
              required
              helperText={city.errorText}
              variant="standard"
              onChange={(e) =>
                setCity({ errorText: "", value: e.target.value })
              }
              label={"Ville"}
              error={city.errorText.length === 0 ? false : true}
              sx={{ width: "40%", pb: 1 }}
              defaultValue={
                eventIdToUpdate
                  ? events.filter(
                      (event) => event.eventId === eventIdToUpdate
                    )[0].city
                  : ""
              }
            />
            <TextField
              required
              helperText={country.errorText}
              label={"Pays"}
              error={country.errorText.length === 0 ? false : true}
              variant="standard"
              onChange={(e) =>
                setCountry({ errorText: "", value: e.target.value })
              }
              sx={{ width: "40%", pb: 1 }}
              defaultValue={
                eventIdToUpdate
                  ? events.filter(
                      (event) => event.eventId === eventIdToUpdate
                    )[0].country
                  : ""
              }
            />
          </Stack>

          <Stack
            sx={!isMobile ? { marginTop: "2rem", justifyContent: "flex-end" } : { marginTop: "1rem",  }}
            direction={!isMobile ? "row" : "column" }
            spacing={2}
          >
            <Button
              startIcon={<AddIcon />}
              onClick={handleSubmitAddEvent}
              variant="contained"
            >
              {eventIdToUpdate ? "Modifier l'évenement" : "Ajouter l'évenement"}
            </Button>
            <Button variant="outlined" onClick={closeModal}>
              Annuler
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};

AddEventFormModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  events: PropTypes.array,
  eventIdToUpdate: PropTypes.number,
  setAlert: PropTypes.func.isRequired,
};

export default AddEventFormModal;
