import React, { useState , useEffect } from 'react'
import {Box, Stack} from "@mui/material";

const Footer = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const isMobile = width <= 767;
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
return (
    <Box sx={!isMobile ? { ml: '1rem', mr: '1rem' } : { ml: '1rem', mr: '0rem', fontSize : '0.8rem'}}>
        <Stack direction={!isMobile ? "row" : "column" } justifyContent="space-between">
            <span>Copyright © 2023. Tous droits réservés DDay</span> 
            <span>contact support : clotildefauchille@gmail.com</span>
    </Stack>
</Box>
)
};
export default Footer;