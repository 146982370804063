import React from 'react'
import { useSelector } from "react-redux";

const ActiveMenu = () => {
    const websites = useSelector((state) => state.websites);

return (
    <svg className="header__highlight" width="80" height="2" viewBox="0 0 101 4" fill={websites.websiteInfos.titleColor} xmlns="http://www.w3.org/2000/svg">
        <path fill={websites.websiteInfos.titleColor} d="M1 3C78.8475 2.80606 57.0538 1 101 1" stroke={websites.websiteInfos.titleColor} strokeWidth="2" />
    </svg>
)
};
export default ActiveMenu;


