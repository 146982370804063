export const guestTableHeadFilter = [
  "firstname",
  "lastname",
  "email",
  "isFirstEmailSend",
  "isSecondEmailSend",
  "willBePresent",
  "partnerWillBePresent",
  "partnerFirstName",
  "partnerLastName",
  "numberOfChildren",
  "aboutFoodRemark",
  "lastLogin",
];

export const labelGuestTableHead = [
  {id:"firstname", label: "Prénom", type: "string"},
  {id: "lastname", label: "Nom", type: "string"},
  {id: "email", label: "Email", type: "string"},
  {id: "groupe", label: "Groupe", type: "select" },
  {id: "type", label: "Type", type: "select" },
  {id: "isFirstEmailSend", label: "Envoi invitation?", type: "boolean"},
  {id: "willBePresent", label: "Présence", type: "string"},
  {id: "partnerWillBePresent", label: "Présence conjoint", type: "string"},
  {id: "partnerFirstName", label: "Prénom conjoint", type: "string"},
  {id: "partnerLastName", label: "Nom conjoint", type: "string"},
  {id: "numberOfChildren", label: "Nombre enfants", type: "number"},
  {id: "aboutFoodRemark", label: "Remarques", type: "string"},
  {id: "lastLogin", label: "Date connexion"}
]

export const validateEmail = (email) => {
    //http://jsfiddle.net/ghvj4gy9/
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const getTotal = (allGuests, key, value) => {
  return allGuests.reduce(
    (accumulateur, valeurCourante) => {
      if (valeurCourante[key] === value) {
        const truth = true;
        return accumulateur + truth;
      } else {
        const truth = false;
        return accumulateur + truth;
      }
    },
    0
  );
}

export const getTotalNumber = (allGuests, key) => {
  return allGuests.reduce(
    (accumulateur, valeurCourante) => {
      return accumulateur + valeurCourante[key]
    },
    0
  );
}