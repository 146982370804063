import { configureStore } from '@reduxjs/toolkit';
import guests from '../reducer/guests.js';
import displayGuestPage from '../reducer/displayGuestPage.js';
import events from '../reducer/events.js';
import places from '../reducer/places.js';
import websites from '../reducer/websites.js';
import organizers from '../reducer/organizers';
import messages from '../reducer/messages';
import users from '../reducer/users';
//configureStore automatically sets up the store with good default settings
// const store = configureStore({
//   reducer: rootReducer,
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
// })
export default configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    reducer: {
        guests,
        displayGuestPage,
        events,
        places,
        websites,
        organizers,
        messages,
        users
    },
});