import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
//import CloseCross from "../../svg/CloseCross.svg";
import { validateEmail } from "../../../utils/guest.js";
import { Button, Stack, TextField, Typography, Paper, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  displayGuestForm,
  setDisabledBtnAddGuest,
  setMessageAlert,
} from "../../../reducer/displayGuestPage.js";
import { addOneGuest, getAllGuests, setIsAnyGuestAddingAction } from "../../../reducer/guests.js";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {capitalizeFirstLetter} from '../../../utils/user';


const AddGuestForm = (props) => {
  const [firstName, setFirstName] = useState({ errorText: "", value: null });
  const [lastName, setLastName] = useState({ errorText: "", value: null });
  const [groupe, setGroupe] = useState('');
  const [type, setType] = useState('');
  const [email, setEmail] = useState({ errorText: "", value: null });
  const { setAlert } = props;
  const dispatch = useDispatch();
  const guests = useSelector((state)=>state.guests);
  const organizers = useSelector((state) => state.organizers);
  const websites = useSelector((state) => state.websites);
  const token = useSelector((state) => state.users.token);
  const displayAlertMessage = useSelector(
    (state) => state.displayGuestPage.messageAlert
  );
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const handleOnSubmitAddGuest = async (e) => {
    //TODO: verifier si existe en bdd
    if (firstName.value === null || firstName.value.length === 0) {
      setFirstName({ errorText: "Indiquez votre prénom ", value: null });
      return;
    }
    if (lastName.value === null || lastName.value.length === 0) {
      setLastName({ errorText: "Indiquez votre nom", value: null });
      return;
    }
    if (email.value === null || email.value.length === 0) {
      setEmail({ errorText: "Indiquez votre mail", value: null });
      return;
    }
    if (!validateEmail(email.value)) {
      setEmail({ errorText: "Votre email n'est pas valide", value: null });
      return;
    }
    const result = await dispatch(
      addOneGuest({
        organizerId: organizers.currentOrganizerId,
        websiteId: websites.currentWebsiteId,
        firstname: firstName.value,
        lastname: lastName.value,
        email: email.value,
        groupe,
        type,
        token
      })
    );
    // if(result.payload.status===500&& result.payload.message){
    //   setAlert({ display: true, message: result.payload.message, type: 'error' });
    // }
    if (result.payload.status === 201) {
      setAlert({
        display: true,
        message: "Votre invité a bien été ajouté",
        type: "success",
      });
      const param = {
        organizerId: organizers.currentOrganizerId,
        websiteId: websites.currentWebsiteId,
        page: guests.page + 1,
        limit: guests.rowsPerPage,
        token
      };
      await dispatch(getAllGuests(param));
      dispatch(displayGuestForm(false));
      dispatch(setDisabledBtnAddGuest(false));
      dispatch(setIsAnyGuestAddingAction(true))
    }
    if (result.payload.status === 500 && result.payload.message) {
      setAlert({
        display: true,
        message: result.payload.message,
        type: "error",
      });
    }
  };

  const handleOnCancelAddGuest = (e) => {
    console.log("onCancelAddGuest");
    dispatch(displayGuestForm(false));
    dispatch(setDisabledBtnAddGuest(false));
    dispatch(setMessageAlert({ display: false, message: "" }));
  };

  return (
    <Paper sx={!isMobile ? { mb: 2, p: 2 } : { p: 1, pb:8 }}>
      <Stack direction={!isMobile ? "row" : 'column'} spacing={2}>
        <TextField
          label="Prénom"
          variant="standard"
          onChange={(e) =>
            setFirstName({ errorText: "", value: e.target.value })
          }
          // value={inputFirstName}
          required
          sx={!isMobile ? { width: "25%" } : { width: "100%" }}
          helperText={firstName.errorText}
          error={firstName.errorText.length === 0 ? false : true}
        />
        <TextField
          label="Nom"
          variant="standard"
          onChange={(e) =>
            setLastName({ errorText: "", value: e.target.value })
          }
          // value={inputLastName}
          required
          sx={!isMobile ? { width: "25%" } : { width: "100%" }}
          helperText={lastName.errorText}
          error={lastName.errorText.length === 0 ? false : true}
        />
        <TextField
          label="Email"
          variant="standard"
          type="email"
          onChange={(e) => setEmail({ errorText: "", value: e.target.value })}
          // value={inputEmail}
          required
          sx={!isMobile ? { width: "50%" } : { width: "100%" }}
          helperText={email.errorText}
          error={email.errorText.length === 0 ? false : true}
        />

        <Stack direction="row" spacing={2}>
          <FormControl variant="standard" sx={!isMobile ? {  minWidth: 120 } : {width: '50%'}}>
            <InputLabel id="groupe">Groupe</InputLabel>
            <Select
              labelId="groupe"
              id="groupe"
              value={groupe}
              onChange={(e) => setGroupe(e.target.value)}
              label="Groupe"
            >
              <MenuItem sx={!isMobile ? {} : {pb:-1, mb:-1}} value={"Commun"}>Commun</MenuItem>
              <MenuItem sx={!isMobile ? {} : {pb:-1, mb:-1}} value={capitalizeFirstLetter(organizers.currentOrganizerInfos.firstname)}>{capitalizeFirstLetter(organizers.currentOrganizerInfos.firstname)}</MenuItem>
              <MenuItem sx={!isMobile ? {} : {pb:-1, mb:-1}} value={capitalizeFirstLetter(organizers.currentOrganizerInfos.firstnamePartner)}>{capitalizeFirstLetter(organizers.currentOrganizerInfos.firstnamePartner)}</MenuItem>
            </Select>
          </FormControl>
            <FormControl variant="standard" sx={!isMobile ? { m: 1, minWidth: 120 } : {width: '50%'}}>
            <InputLabel id="Type">Type</InputLabel>
            <Select
              labelId="Type"
              id="demo-simple-select-standard"
              value={type}
              onChange={(e) => setType(e.target.value)}
              label="Type"
            >
              <MenuItem value={"Amis"}>Amis</MenuItem>
              <MenuItem value={"Famille"}>Famille</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack direction={!isMobile ? "row" : "column"} spacing={2}>
          <Button
            variant="contained"
            type="submit"
            onClick={handleOnSubmitAddGuest}
            sx={{ pl: '2rem', pr: '2rem' }}
          >
            Ajouter
          </Button>
          <Button
            variant="outlined"
            type="submit"
            onClick={handleOnCancelAddGuest}
            sx={{ pl: '2rem', pr: '2rem' }}
          >
            Annuler
          </Button>
        </Stack>
      </Stack>
      {displayAlertMessage.display && (
        <Stack sx={{ mt: 2 }} direction="row" spacing={2}>
          <WarningAmberIcon></WarningAmberIcon>
          <Typography>
            <i>{displayAlertMessage.message}</i>
          </Typography>
        </Stack>
      )}
    </Paper>
  );
};

AddGuestForm.propTypes = {
  setAlert: PropTypes.func.isRequired,
};
export default AddGuestForm;
