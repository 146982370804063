import React, { useState, useEffect } from "react";
import { Box, Modal, TextField, Button, Stack } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import {updateWebsite, getWebsiteInfos} from '../../../reducer/websites.js';

const AddWebsiteInfoForm = (props) => {
  const { setDisplayAddWebsiteInfo, displayAddWebsiteInfo, type } = props;
  const dispatch = useDispatch();
  const websites = useSelector((state) => state.websites);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;
  const [websiteInfo, setWebsiteInfo] = useState({ errorText: "", value: null, type });

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const style = !isMobile ? {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  } : {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "90%",
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 1,
  };
  
  const handleOnSubmitAddTitle = async () => {
    if (websiteInfo.value === null || websiteInfo.value.length === 0) {
      setWebsiteInfo({ errorText: `Indiquez votre ${type === 'title' ? 'titre' : type}`, value: null, type});
      return;
    }

    const response = await dispatch(updateWebsite({ value: websiteInfo.value, type: websiteInfo.type, websiteId: websites.websiteInfos.websiteId }));
    if(response.payload.status === 200) {
      dispatch(getWebsiteInfos(websites.websiteInfos.websiteId));
    }
    setDisplayAddWebsiteInfo(false);
  };
  const handleOnChange = (e) => {
    setWebsiteInfo({errorText: "", value : e.target.value, type: e.target.name});
  };

  return (
    <Modal
      open={displayAddWebsiteInfo}
      onClose={(e, reason) => {
        if (reason !== "backdropClick") {
          setDisplayAddWebsiteInfo(false);
        }
      }}
    >
      <Box sx={style}>
        <Stack direction={!isMobile ? "row" : "column"} sx={{ justifyContent: "space-between" }}>
          {type === 'date' ? <TextField
            required  
            helperText={websiteInfo.errorText}
            sx={!isMobile ? { width: "55%", mr: 2 } : { width: "100%", mr: 0, mt: 3, mb : 3}}
            id="date"
            name={type}
            onChange={handleOnChange}
            error={websiteInfo.errorText.length === 0 ? false : true}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          /> : <TextField
            label={type}
            name={type}
            variant="standard"
            onChange={handleOnChange}
            // value={websiteInfo.value}
            required
            sx={!isMobile ? { width: "55%"} : { width: "90%", mb: 3, mt: 2}}
            error={websiteInfo.errorText.length === 0 ? false : true}
            helperText={websiteInfo.errorText}
          />}
          
          <Stack direction="row" spacing={2} sx={!isMobile ? {} : { mb : 2}}>
            <Button
              variant="contained"
              type="submit"
              onClick={handleOnSubmitAddTitle}
            >
              Ajouter
            </Button>
            <Button
              variant="outlined"
              type="submit"
              onClick={() => setDisplayAddWebsiteInfo(false)}
            >
              Annuler
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};
export default AddWebsiteInfoForm;
