import React, { useState, useEffect } from "react";
import {
  IconButton,
  Divider,
  Drawer,
  Stack,
  Typography,
  FormControl,
  Box,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled, useTheme } from "@mui/material/styles";
import { SketchPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import {
  updateColorsWebsite,
  updateFontTitleWebsite,
  updateDefaultColor,
  updateTitleColorWebsite
} from "../../../reducer/websites.js";
import "./ColorEdit.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { getWebsiteInfos } from '../../../reducer/websites';

const ColorEdit = ({ setOpen, open }) => {
  const drawerWidth = 320;
  const websites = useSelector((state) => state.websites);
  const [primaryColor, setPrimaryColor] = useState(websites.websiteInfos.primaryColor);
  const [secondaryColor, setSecondaryColor] = useState(websites.websiteInfos.secondaryColor);
  const [thirdColor, setThirdColor] = useState(websites.websiteInfos.thirdColor);
  const [defaultColor, setDefaultColor] = useState(false);
  const dispatch = useDispatch();
  const [font, setFont] = useState(websites.websiteInfos.font);
  const [displayColorPickerPrimaryColor, setDisplayColorPickerPrimaryColor] =
    useState(false);
  const [titleColor, setTitleColor] = useState(websites.websiteInfos.titleColor);
  const [
    displayColorPickerSecondaryColor,
    setDisplayColorPickerSecondaryColor,
  ] = useState(false);
  const [displayColorPickerThirdColor, setDisplayColorPickerThirdColor] =
    useState(false);

  useEffect(() => { 
    const fetchWebsiteInfos = async () => {
      await dispatch(getWebsiteInfos(websites.currentWebsiteId));
    }
    fetchWebsiteInfos();
  }, [primaryColor, secondaryColor, thirdColor, defaultColor, font, titleColor])
  

  const handleOnChangeCompletePrimaryColor = async (color) => {
    const res = await dispatch(updateColorsWebsite({ type: "primaryColor", color: color.hex, websiteId: websites.currentWebsiteId }));
    if(res.payload.status===200){
      setPrimaryColor(color.hex);
    }
  };
  
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  }));
  
  const handleOnChangeCompleteSecondaryColor = async (color) => {
    const res = await dispatch(updateColorsWebsite({ type: "secondaryColor", color: color.hex, websiteId: websites.currentWebsiteId }));
    if (res.payload.status === 200) {
      setSecondaryColor(color.hex);
    }
  };

  const handleOnChangeCompleteThirdColor = async (color) => {
    const res = await dispatch(updateColorsWebsite({ type: "thirdColor", color: color.hex, websiteId: websites.currentWebsiteId }));
    if (res.payload.status === 200) {
      setThirdColor(color.hex);
    }
  };

  const handleSetDefaultColor = async () => {
   const res = await dispatch(updateDefaultColor(websites.currentWebsiteId));
    if (res.payload.status === 200) {
      setDefaultColor(true);
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChangeTypo = async (e) => {
    console.log(e.target.value);
    const res = await dispatch(updateFontTitleWebsite({ font: e.target.value, websiteId: websites.currentWebsiteId }));
    if (res.payload.status === 200) {
      setFont(e.target.value);
    }
  };

  const handleChangeTitleColor = async (e) => {
    const res = await dispatch(updateTitleColorWebsite({titleColor: e.target.value, websiteId: websites.currentWebsiteId}))
    if (res.payload.status === 200) {
      setTitleColor(e.target.value);
    }
  }
  return (
    <div className="test">
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Stack sx={{ padding: "1rem" }}>
          <Box sx={{ width: "100%" }}>
            {/* -------------colors --------------*/}
            <Typography variant="h4">Trouvez vos couleurs :</Typography>
            <Stack>
              <Button
                startIcon={
                  displayColorPickerPrimaryColor ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                }
                sx={{ mt: "1rem" }}
                variant="contained"
                onClick={() => {
                  setDisplayColorPickerSecondaryColor(false);
                  setDisplayColorPickerThirdColor(false);
                  setDisplayColorPickerPrimaryColor(
                    !displayColorPickerPrimaryColor
                  );
                }}
                color="colorPickerOne"
              >
                Couleur de fond
              </Button>
              {displayColorPickerPrimaryColor && (
                <div className="popover">
                  <div
                    className="cover"
                    onClick={() => setDisplayColorPickerPrimaryColor(false)}
                  />
                  <SketchPicker
                    color={primaryColor}
                    onChangeComplete={handleOnChangeCompletePrimaryColor}
                  />
                </div>
              )}
              <Button
                sx={{ mt: "1rem" }}
                startIcon={
                  displayColorPickerSecondaryColor ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                }
                variant="contained"
                onClick={() => {
                  setDisplayColorPickerPrimaryColor(false);
                  setDisplayColorPickerThirdColor(false);
                  setDisplayColorPickerSecondaryColor(
                    !displayColorPickerSecondaryColor
                  );
                }}
                color="colorPickerTwo"
              >
                Couleur clair
              </Button>
              {displayColorPickerSecondaryColor && (
                <div className="popover">
                  <div
                    className="cover"
                    onClick={() => setDisplayColorPickerSecondaryColor(false)}
                  />
                    <SketchPicker
                      color={secondaryColor}
                      onChangeComplete={handleOnChangeCompleteSecondaryColor}
                    />
                </div>
              )}
              <Button
                sx={{ mt: "1rem" }}
                startIcon={
                  displayColorPickerThirdColor ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                }
                variant="contained"
                onClick={() => {
                  setDisplayColorPickerPrimaryColor(false);
                  setDisplayColorPickerSecondaryColor(false);
                  setDisplayColorPickerThirdColor(
                    !displayColorPickerThirdColor
                  );
                }}
                color="colorPickerThree"
              >
                Couleur foncée
              </Button>
            </Stack>
            {displayColorPickerThirdColor && (
              <div className="popover">
                <div
                  className="cover"
                  onClick={() => setDisplayColorPickerThirdColor(false)}
                />
                <SketchPicker
                  color={thirdColor}
                  onChangeComplete={handleOnChangeCompleteThirdColor}
                />
              </div>
            )}

            <Typography sx={{ mt: "1rem" }} variant="h4">
              Initialisez les couleurs par défault :
            </Typography>
            <Button
              sx={{ mt: "1rem", width: "100%" }}
              variant="contained"
              onClick={handleSetDefaultColor}
              color="defaultColorPickup"
            >
              Couleurs DDAY
            </Button>
            {/* --------------font ----------------*/}
            <Typography sx={{ mt: "1rem" }} variant="h4">
              Trouvez la typo de vos titres :
            </Typography>
            <FormControl sx={{ mt: "1rem" }} fullWidth>
              <InputLabel id="demo-simple-select-label">Police</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={font}
                label="Font"
                onChange={handleChangeTypo}
              >
                <MenuItem value={"'Sacramento', cursive"} id="Default">
                  Font DDAY
                </MenuItem>
                <MenuItem value={"'Rubik Microbe', cursive"} id="Rubik-Microbe">
                  Rubik-Microbe
                </MenuItem>
                <MenuItem value={"'Sono', sans-serif"} id="Sono">
                  Sono
                </MenuItem>
                <MenuItem
                  value={"'Dancing Script', cursive"}
                  id="Dancing-Script"
                >
                  Dancing-Script
                </MenuItem>
                <MenuItem value={"'Anton', sans-serif"} id="Anton">
                  Anton
                </MenuItem>
              </Select>
            </FormControl>

            <Typography sx={{ mt: "1rem" }} variant="h4">
              Changez la couleur de vos titres
            </Typography>
            <FormControl sx={{ mt: "1rem" }} fullWidth>
              <InputLabel id="couleur-titre-label">Couleur Titres</InputLabel>
              <Select
                labelId="couleur-titre-label"
                id="couleur-titre"
                value={titleColor}
                label="Couleur Titres"
                onChange={handleChangeTitleColor}
              >
                <MenuItem value={"black"}>
                  Noirs
                </MenuItem>
                <MenuItem value={"white"}>
                  Blancs
                </MenuItem>
              </Select>
            </FormControl>


          </Box>
        </Stack>
      </Drawer>
    </div>
  );
};
export default ColorEdit;
