import React, { useState, useEffect } from "react";
import { Container, Stack, Typography, Button } from "@mui/material";
import "./HomePageGuest.scss";
import RegisterGuestForm from "./RegisterGuestForm.js";
import LoginGuestForm from "./LoginGuestForm.js";
import { useSelector, useDispatch } from "react-redux";
import { getWebsiteInfos } from "../../reducer/websites";
import { useSearchParams } from "react-router-dom";

const HomeGuest = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const websiteId = searchParams.get("websiteId");
  const [displayRegisterOrganizerForm, setDisplayRegisterOrganizerForm] =
    useState(false);
  const [displayLoginOrganizerForm, setDisplayLoginOrganizerForm] =
    useState(false);
  const [alert, setAlert] = useState({
    display: false,
    message: null,
    type: null,
  });
  const websites = useSelector((state) => state.websites);
    const [width, setWidth] = useState(window.innerWidth);
    const isMobile = width <= 767;
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

  useEffect(() => {
    const fetchInfoWebsite = async () => {
      const res = await dispatch(getWebsiteInfos(websiteId));
      //console.log("res", res.payload);
    };
    fetchInfoWebsite();
  }, []);

  return (
    <Container sx={{ width: "100 %", height: "100vh" }} className="homeGuest__img">
          {websites.websiteInfos !== null &&
              <div>
                  <Stack
                      sx={{
                          textAlign: "center",
                          marginTop: "3rem",
                          fontSize: "4rem",
                      }}
                  >
                      <Typography variant="homePage" sx={!isMobile ? { mb: '3rem', mt: '4rem' } : {}} >Bienvenue sur le site de {websites.websiteInfos.title}</Typography>
                      <Typography sx={!isMobile ? {  marginLeft: "-2rem", fontStyle: "italic" } : { marginLeft: "0rem", mt: 1, fontStyle: "italic" }}>
                          Vous êtes invités à vous incrire pour répondre à notre invitation et
                          décrouvir le planning des festivités!
                      </Typography>
                      {displayRegisterOrganizerForm && (
                          <RegisterGuestForm
                              setIsOpenRegisterForm={setDisplayRegisterOrganizerForm}
                              isOpen={displayRegisterOrganizerForm}
                              setIsOpenLoginForm={setDisplayLoginOrganizerForm}
                              setAlert={setAlert}
                              alert={alert}
                              names={websites.websiteInfos.title}
                              websiteId={websiteId}
                          />
                      )}
                      {displayLoginOrganizerForm && (
                          <LoginGuestForm
                              setIsOpenLoginForm={setDisplayLoginOrganizerForm}
                              isOpen={displayLoginOrganizerForm}
                              setIsOpenRegisterForm={setDisplayRegisterOrganizerForm}
                              setAlert={setAlert}
                              alert={alert}
                              names={websites.websiteInfos.title}
                              websiteId={websiteId}
                          />
                      )}
                  </Stack>
                  <Stack sx={!isMobile ? { display: "flex", flexDirection: "row", justifyContent: "end", marginTop: "10%" } : { display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginTop: "10%" }}>
                      <Button
                          variant="contained"
                          size={!isMobile ? "large" : "medium"}
                          type="submit"
                          onClick={() => setDisplayRegisterOrganizerForm(true)}
                      >
                          S'inscrire
                      </Button>
                      <Button
                          sx={{ marginLeft: "0.5rem" }}
                          variant="contained"
                          size={!isMobile ? "large" : "medium"}
                          type="submit"
                          onClick={() => setDisplayLoginOrganizerForm(true)}
                      >
                          Se connecter
                      </Button>
                  </Stack>
              </div>
          }
      
    </Container>
  );
};
export default HomeGuest;
