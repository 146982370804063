
import { createTheme } from "@mui/material/styles";


export const theme = (websites) => createTheme({

    typography: {
        fontFamily: "'Roboto', sans-serif",
        h1: { fontFamily: websites.websiteInfos !== null ? websites.websiteInfos.font : "'Sacramento', cursive", fontSize: websites.websiteInfos !== null && (websites.websiteInfos.font === "'Sacramento', cursive" || websites.websiteInfos.font === "'Dancing Script', cursive") ? 57 : 40, '@media (max-width:767px)': { fontSize: websites.websiteInfos !== null && (websites.websiteInfos.font === "'Sacramento', cursive" || websites.websiteInfos.font === "'Dancing Script', cursive") ? '2.2rem' : '1.5rem' }, '@media (max-width: 1190px) and (min-width: 767px)': { fontSize: websites.websiteInfos !== null && (websites.websiteInfos.font === "'Sacramento', cursive" || websites.websiteInfos.font === "'Dancing Script', cursive") ? '2.5rem' : '1.9rem' }, color: websites.websiteInfos !== null ? websites.websiteInfos.titleColor : "black" },
        h2: { fontFamily: websites.websiteInfos !== null ? websites.websiteInfos.font : "'Sacramento', cursive", fontSize: websites.websiteInfos !== null && (websites.websiteInfos.font === "'Sacramento', cursive" || websites.websiteInfos.font === "'Dancing Script', cursive") ? 40 : 30, '@media (max-width:767px)': { fontSize: websites.websiteInfos !== null && (websites.websiteInfos.font === "'Sacramento', cursive" || websites.websiteInfos.font === "'Dancing Script', cursive") ? '1.5rem' : '1.2rem' }, '@media (max-width: 1190px) and (min-width: 767px)': { fontSize: websites.websiteInfos !== null && (websites.websiteInfos.font === "'Sacramento', cursive" || websites.websiteInfos.font === "'Dancing Script', cursive") ? '2rem' : '1.5rem' }, color: websites.websiteInfos !== null ? websites.websiteInfos.titleColor : "black" },
        h3: { fontFamily: websites.websiteInfos !== null ? websites.websiteInfos.font : "'Sacramento', cursive", fontSize: 30, color: websites.websiteInfos !== null ? websites.websiteInfos.titleColor : "black" },
        h4: { fontSize: 18 },
        homePage: { fontFamily: websites.websiteInfos !== null ? websites.websiteInfos.font : "'Sacramento', cursive", fontSize: '@media (max-width:767px)'? 40 : 60},
        coloredText: { color: 'white', fontSize: '1rem' }
    },
    palette: {
        background: {
            default: websites.websiteInfos !== null ? websites.websiteInfos.primaryColor : '#f5f2f0',
        },
        primary: {
            main: websites.websiteInfos !== null ? websites.websiteInfos.secondaryColor : '#d8c7bf',
            contrastText: "#fff",
            dark: websites.websiteInfos !== null ? websites.websiteInfos.thirdColor : '#c7b3ab',
        },
        defaultColorPickup: {
            main: '#d8c7bf',
            contrastText: '#f5f2f0r',
            dark: '#c7b3ab',
        },
        colorPickerOne: {
            main: websites.websiteInfos !== null ? websites.websiteInfos.primaryColor : '#f5f2f0',
            contrastText: "black",
            dark: websites.websiteInfos !== null ? websites.websiteInfos.primaryColor : '#f5f2f0',
        },
        colorPickerTwo: {
            main: websites.websiteInfos !== null ? websites.websiteInfos.secondaryColor : '#d8c7bf',
            contrastText: "black",
            dark: websites.websiteInfos !== null ? websites.websiteInfos.secondaryColor : '#d8c7bf',
        },
        colorPickerThree: {
            main: websites.websiteInfos !== null ? websites.websiteInfos.thirdColor : '#c7b3ab',
            contrastText: "black",
            dark: websites.websiteInfos !== null ? websites.websiteInfos.thirdColor : '#c7b3ab',
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                        borderRadius: '10px',
                        backgroundColor: websites.websiteInfos !== null ? websites.websiteInfos.thirdColor : '#c7b3ab',
                    },
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                standardSuccess: {
                    backgroundColor: "#d4e6b8",
                },
                standardError: {
                    backgroundColor: "#de9999",
                    color: "white",
                },
                standardWarning: {
                    backgroundColor: "orange",
                    color: "white",
                },
                standardInfo: {
                    backgroundColor: "grey",
                    color: "black",
                },
            },
        },
    },
})