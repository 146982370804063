  import React, { useRef, useState, useEffect } from "react";
  // import img from 'src/assets/svg/agenda.svg';
  //import img from "../../assets/img/agenda2.jpg";
  import "./AgendaPage.scss";
  import Event from "./Event.js";
  import PageTitle from "../../PageTitle/PageTitle";
  import { useSelector, useDispatch } from "react-redux";
  import { getAllEvents } from "../../../reducer/events.js";
  import { Button, Paper } from "@mui/material";
  import AddIcon from "@mui/icons-material/Add";
  import AddEventFormModal from "./AddEventFormModal";
  import AlertMessage from "../../AlertMessage/AlertMessage";
  import {
    uploadImage,
    updateWebsiteAgendaImage,
    setIsLoading
  } from "../../../reducer/websites.js";
  import Header from '../Header/Header';
  import Loader from '../../Loader/Loader';

  //TODO pickup date https://mui.com/x/react-date-pickers/date-picker/
  const AgendaPage = () => {
    const inputFileImageRef = useRef(null);
    const dispatch = useDispatch();
    const [displayUpdateEventModal, setDisplayUpdateEventModal] = useState(false);
    const [eventIdToUpdate, setEventIdToUpdate] = useState(null);
    const events = useSelector((state) => state.events);
    const websites = useSelector((state) => state.websites);
    const [imageUploaded, setImageUploaded] = useState(websites.websiteInfos.agendaImage);
    const [openModalAddEvent, setOpenModalAddEvent] = useState(false);
    const [alert, setAlert] = useState({
      display: false,
      message: null,
      type: null,
    });
    const token = useSelector((state) => state.users.token);
    const base_media_url = process.env.NODE_ENV === "production" ? `${process.env.REACT_APP_MEDIA_PROD}` : `${process.env.REACT_APP_MEDIA_DEV}`
    const [width, setWidth] = useState(window.innerWidth);
    const isMobile = width <= 767;

    //console.log("websites", websites);

    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    }
    useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
    }, []);

    useEffect(() => {
      const fetchEvents = async () => {
        const body = {
          websiteId: websites.currentWebsiteId,
          token
        }
         await dispatch(getAllEvents(body));
      };
      if (token !== null && websites.currentWebsiteId!==null){
          fetchEvents();
      }
    }, [alert, websites.currentWebsiteId]);

    const handleClickImage = (e) => {
      inputFileImageRef.current.click();
    };

    const handleFileChange = async (e) => {

      let fileObject = e.target.files[0];
      const maxFileSize = 4 * 1024 * 1024; // 4 MB
    
      //on verifie la taille et l'extension du fichier
      if (fileObject.size > maxFileSize) {
        setAlert({
          display: true,
          message: "la taille du fichier est trop grande",
          type: "error",
        });
      } else {
      
      const extension = fileObject.name.substr(
        fileObject.name.lastIndexOf(".") + 1
      );
      const blob = fileObject.slice(0, fileObject.size, `image/${extension}`);
      const newFile = new File([blob], `${websites.websiteInfos.websiteId}-agendaImage.${extension}`, {
        type: `image/${extension}`,
      });

      if (!fileObject) {
        return;
      }
      const data = new FormData();
      data.append("file", newFile);
      const body = {
        data,
        token,
        websiteId: websites.currentWebsiteId
      }
      const resUpload = await dispatch(uploadImage(body));
      if(resUpload.payload.status === 200){
        const body = {
          agendaImage: `${base_media_url}/${websites.websiteInfos.websiteId}-agendaImage.${extension}`,
          websiteId: websites.currentWebsiteId,
        };
        dispatch(updateWebsiteAgendaImage(body));
        setImageUploaded(`${base_media_url}/${websites.websiteInfos.websiteId}-agendaImage.${extension}`);
      }
      if (resUpload.payload.status === "error") {
        setAlert({
          dipslay: true,
          message: "Votre image ne peut être chargée",
          type: "error",
        });
      }
      if(resUpload.payload === 401) {
        //TODO msg alert erroe ne s'affiche pas
        setAlert({
          dipslay: true,
          message: "Vous n'êtes pas autorisé à changer cette image",
          type: "error",
        });
      }
      }
    };

    return (
      <div className="agenda">
        <Header />
        <PageTitle name="Agenda" />
        {alert.display && <AlertMessage setAlert={setAlert} alert={alert} />}
        <div className="agenda__container">
          <div className="agenda__container-events">
            {events.length > 0 ?
              events.map((event, index) => (
                <Event
                  setEventIdToUpdate={setEventIdToUpdate}
                  key={index}
                  event={event}
                  setDisplayUpdateEventModal={setDisplayUpdateEventModal}
                />
              )) : <Paper sx={{ width: '95%', padding: '1rem' }}><strong>Info</strong> <br /><br />Ajoutez vos évènements (clic sur le bouton "ajouter un évènement"). <br /> <br /> Personnalisez votre site en changeant les photos (clic sur la photo), le titre et la date (clic sur les stylos au niveau du menu supérieur), les couleurs et la police (clic sur le pinceau au niveau du menu supérieur).</Paper>}
          </div>
          {displayUpdateEventModal && (
            <AddEventFormModal
              eventIdToUpdate={eventIdToUpdate}
              isOpen={displayUpdateEventModal}
              setIsOpen={setDisplayUpdateEventModal}
              events={events}
              setAlert={setAlert}
              alert={alert}
            />
          )}
         
          {!isMobile&&
          <>
          <input
            style={{ display: "none" }}
            ref={inputFileImageRef}
            accept={".jpg, .png, .gif, .svg, .jpeg"}
            type="file"
            onChange={handleFileChange}
          />
          <div className="agenda__container-img">
            <img
              className="agenda__img"
              src={`${imageUploaded}?id=${Date.now()}`}
              alt="wedding"
              onClick={handleClickImage}
            />
          </div>
          </>
          }
        </div>
        {openModalAddEvent && (
          <AddEventFormModal
            isOpen={openModalAddEvent}
            setIsOpen={setOpenModalAddEvent}
            setAlert={setAlert}
            alert={alert}
          />
        )}
        <Button
          id="agenda__add-event-btn"
          startIcon={<AddIcon />}
          onClick={() => setOpenModalAddEvent(true)}
          variant="contained"
        >
          Ajouter un évènement
        </Button>
       
      </div>
  
    );
    
  };

  AgendaPage.propTypes = {};

  export default AgendaPage;
