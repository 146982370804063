import React, {useState, useEffect} from "react";
import { NavLink } from "react-router-dom";
import ActiveMenu from "./icon/activeMenu";
import Book from "./icon/book";
import Map from "./icon/map";
import Response from "./icon/response";
import AgendaNav from "./icon/agendaNav";
import "./Menu.scss";
import { useSelector } from "react-redux";
import {Box} from "@mui/material";

const MenuGuest = () => {
  const websites = useSelector((state) => state.websites);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (
    <Box sx={!isMobile ? {} : {backgroundColor: websites.websiteInfos.primaryColor, pb: 1}} className="menu__navbar">
      <NavLink
        to={`/agendaGuest/?websiteId=${websites.currentWebsiteId}`}
        className={(navData) => (navData.isActive ? "menu__selected" : "")}
      >
        <AgendaNav></AgendaNav>
        <ActiveMenu></ActiveMenu>
      </NavLink>
      <NavLink
        to={`/confirmationGuest/?websiteId=${websites.currentWebsiteId}`}
        className={(navData) => (navData.isActive ? "menu__selected" : "")}
      >
        <Response></Response>
        <ActiveMenu></ActiveMenu>
      </NavLink>
      <NavLink
        to={`/infosGuest/?websiteId=${websites.currentWebsiteId}`}
        className={(navData) => (navData.isActive ? "menu__selected" : "")}
      >
        <Map></Map>
        <ActiveMenu></ActiveMenu>
      </NavLink>
      <NavLink
        to={`/livreOrGuest/?websiteId=${websites.currentWebsiteId}`}
        className={(navData) => (navData.isActive ? "menu__selected" : "")}
      >
        <Book></Book>
        <ActiveMenu></ActiveMenu>
      </NavLink>
    </Box>
  );
};
export default MenuGuest;
