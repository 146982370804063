import React, {useState} from 'react';
import Box from '@mui/material/Box';
import { Button, TextField, Typography, Modal }from '@mui/material';
import { sendPasswordReset } from "../../services/firebase.js";



export default function ForgetMailSection({ open, setOpen, modalEmail, setModalEmail, setAlert }) {
 
    const handleForgetPassword = async () => {
        const res = await sendPasswordReset(modalEmail.value);
        console.log("res", res);
        setModalEmail(false)
        if(res.status===200){
            setAlert({
                display: true,
                message: "Un mail pour changer votre mot de passe vous a été envoyé",
                type: "success",
            });
        }
    }
    return (
        <div>
                <Box>
                <Typography id="modal-modal-title" sx={{ mt: 1 }}>
                        Veuillez renseigner votre email, un lien va vous être envoyé afin de changer de mot de passe
                    </Typography>
                    <TextField autoComplete="given-name"
                        name="email"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        onChange={(e) =>
                            setModalEmail({ errorText: "", value: e.target.value })
                        }
                        variant="standard"
                        autoFocus
                        //error={modalEmail.errorText.length === 0 ? false : true}
                        helperText={modalEmail.errorText} sx={{ width: '100%', mb: 1}}/>
                    <Button variant="contained" size="large" onClick={handleForgetPassword}>Envoyer</Button>
                </Box>
        </div>
    );
}