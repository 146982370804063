import React from 'react'
import './PageTitle.scss';
import Star from "./star";
//import star from "../../assets/svg/star.svg";
import stars from "../../assets/svg/stars.svg";
import { Typography } from "@mui/material";

const PageTitle = ({name}) => {


return (
    <div className="title__img--title">
        <Star></Star>
        {/* <img src={star} alt="star" className="title__star" /> */}
        {/* <img src={stars} alt="stars" className="agenda__stars" /> */}
        <Typography className="title__title" variant="h2">
            {name}
        </Typography>
    </div>
)
};
export default PageTitle;