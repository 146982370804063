import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Grid,
  Link,
  Button,
  TextField,
  Stack,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { signInWithEmailAndPassword } from "../../services/firebase.js";
import AlertMessage from "../AlertMessage/AlertMessage.js";
import {useDispatch} from 'react-redux';
import { setCurrentWebsiteId } from '../../reducer/websites';
import {addGuestCustomClaimsIfUserAnyClaims} from '../../reducer/guests';
import ForgetMailSectionGuest from './ForgetMailSectionGuest.js';

const LofinOrganizerForm = ({ setIsOpenLoginForm, isOpen, setIsOpenRegisterForm, setAlert,
  alert, names, websiteId }) => {
  const dispatch = useDispatch();
  const [modalEmail, setModalEmail] = useState({ errorText: "", value: null })
  const [displayInputMail, setDisplayInputMail] = useState(false);
  const [email, setEmail] = useState({ errorText: "", value: null });
  const [password, setPassword] = React.useState({
    errorText: "",
    amount: "",
    value: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  //TODO champs required front-matter
  //Afficher message erreur de firebase via alert
  //ajouter se connecter ac googleapis
  const closeModal = () => {
    setIsOpenLoginForm(false);
  };
  const handleLogin = async () => {
    if (email.value === null || email.value.length === 0) {
      setEmail({ errorText: "Indiquez votre mail ", value: null });
      return;
    }
    if (password.value === null || password.value.length === 0) {
      setEmail({ errorText: "Indiquez votre mot de passe ", value: null });
      return;
    }
    const res = await signInWithEmailAndPassword(email.value, password.value);
    if (res.status === 200) {
      const uid = res.uid;
      const resIsAnyClaims = await dispatch(addGuestCustomClaimsIfUserAnyClaims({ uid, websiteId }));
      if(resIsAnyClaims.payload.status===200){
        dispatch(setCurrentWebsiteId(websiteId));
        closeModal();
      }
    }
    if (res.error) {
      console.log(res.error);
      setAlert({
        display: true,
        message: res.error,
        type: "error",
      });
    } 
  };
  const handleClickShowPassword = () => {
    setPassword({
      ...password,
      showPassword: !password.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const style = !isMobile ? {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  } : {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "85%",
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 1,
  };
  return (
    <Modal
      open={isOpen}
      onClose={(e, reason) => {
        if (reason !== "backdropClick") {
          closeModal();
        }
      }}
    >
      <Box sx={style}>
        <Stack
          sx={!isMobile ? { fontSize: "2rem", paddingTop: "2rem", paddingBottom: "2rem" } : { fontSize: "2rem", paddingTop: "1.5rem", paddingBottom: "0rem" }}
        >
        <Typography variant="h2">
            Se connecter sur le site de {names}
        </Typography>
        </Stack>
        <Box>
          {alert.display && (
            <AlertMessage setAlert={setAlert} alert={alert} />
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail({ errorText: "", value: e.target.value })}
            error={email.errorText.length === 0 ? false : true}
            helperText={email.errorText}
          />
          <FormControl sx={{ width: "100%" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={password.showPassword ? "text" : "password"}
              value={password.value}
              onChange={(e) =>
                setPassword({ ...password, errorText:"", value: e.target.value })
              }
              error={password.errorText.length === 0 ? false : true}
              // helperText={password.errorText}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {password.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleLogin}
          >
            Me connecter
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" onClick={() => setDisplayInputMail(true)}>
                Mot de passe oublié ?
              </Link>
            </Grid>
            <Grid item>
              <Link component="button"
                variant="body2"
                onClick={() => {
                  setIsOpenRegisterForm(true);
                  setIsOpenLoginForm(false)
                }}>
                {"Je n'ai pas de compte? M'inscrire"}
              </Link>
            </Grid>
            {displayInputMail && <ForgetMailSectionGuest open={displayInputMail} setOpen={setDisplayInputMail} modalEmail={modalEmail} setModalEmail={setModalEmail} setAlert={setAlert} />}
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};
export default LofinOrganizerForm;
