import { createSlice } from "@reduxjs/toolkit";

export const users = createSlice({
    name: "users",
    initialState: {
        token: null,
    },
    reducers: {
        setToken: (state, action) => {
            //console.log("action.payload", action.payload);
            state.token = action.payload;
        },
    },
    
});

export const { setToken } = users.actions;


export default users.reducer;
