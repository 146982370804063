import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TableContainer, Table, TableBody, TableRow, TableCell, Checkbox, TablePagination, InputLabel, Paper, Box, FormControl, Select, MenuItem  } from "@mui/material";
import GuestTableToolbar from "./GuestTableToolbar.js";
import GuestTableHead from "./GuestTableHead.js";
import { getAllGuests, getAllGuestsNoLimit, setPage, setRowsPerPage, updateGroupFromGuest } from "../../../reducer/guests";
import { labelGuestTableHead } from "../../../utils/guest.js";
import { momentLocaleConfig } from "../../../utils/momentLocalConfig.js";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import { capitalizeFirstLetter } from '../../../utils/user';

const GuestTable = (props) => {
  moment.updateLocale('fr', momentLocaleConfig)
  const {setAlert} = props;
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [allGuestsNoLimit, setAllGuestsNoLimit]= React.useState([]);
  const page = useSelector((state) => state.guests.page);
  const rowsPerPage = useSelector((state) => state.guests.rowsPerPage);
  const organizers = useSelector((state) => state.organizers);
  const websites = useSelector((state) => state.websites);
  const token = useSelector((state) => state.users.token);
  const [searchQuery, setSearchQuery] = useState('');
  const [type, setType] = useState('');
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;
  const guestInfos = useSelector((state) => state.guests.guests.result);
  const countAllGuests = useSelector((state) => state.guests.guests.count);
  const allGuestInfos = useSelector((state) => state.guests.allGuests);


  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const filterDataBySearchInput = (query, rows) => {
    if (!query) {
      return rows;
    } else {
      return rows.filter((row) => row.firstname.toLowerCase().includes(query) || row.lastname.toLowerCase().includes(query) || row.email.toLowerCase().includes(query) );
    }
  };

  useEffect(() => {
    const param = {
      organizerId: organizers.currentOrganizerId,
      websiteId: websites.currentWebsiteId,
      page: page + 1,
      limit: rowsPerPage,
      token
    }
    if (token !== null && organizers.currentOrganizerId !== null && websites.currentWebsiteId !== null) {
    dispatch(getAllGuests(param));
    }
  }, [page, rowsPerPage]);



  const handleSelectAllClick = async (event) => {
    if (event.target.checked) {
      const response = await dispatch(getAllGuestsNoLimit({ organizerId: organizers.currentOrganizerId, websiteId: websites.currentWebsiteId, token }));
         const newSelected = response.payload.map((guest) => guest.guestId);
         setAllGuestsNoLimit(newSelected);
         setSelected(newSelected);
        return;
    }
    setSelected([]);
  };

  const handleClickRowCheckBox = (event, guestId) => {
    const selectedIndex = selected.indexOf(guestId);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, guestId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleOnChangeSelectGroup = async (e, guestId, field) => {
    const body= {
      guestId,
      value: e.target.value,
      websitesId: websites.currentWebsiteId,
      oganizerId: organizers.currentOrganizerId,
      field
    }
    const update = await dispatch(updateGroupFromGuest(body));
    
    if(update.payload.status===200){
      const param = {
        organizerId: organizers.currentOrganizerId,
        websiteId: websites.currentWebsiteId,
        page: page + 1,
        limit: rowsPerPage,
        token
      }
      dispatch(getAllGuests(param));
    };
    
  }
  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    // setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
  };


  const isSelected = (guestId) => selected.indexOf(guestId) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - guestInfos.length) : 0;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
   const descendingComparator=(a, b, orderBy)=> {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getComparator = (order, orderBy) =>{
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  console.log('searchQuery.value', searchQuery.value);

  return (
    <Box sx={!isMobile ? { width: "100%", pl: 2, pr:2 } : { width: "100%", pl: 1, pr:1 }}>
      {guestInfos && 
        <Paper sx={{ width: "100%", mb: 2 }}>
          <GuestTableToolbar searchQuery={searchQuery} setSearchQuery={setSearchQuery} setAlert={setAlert} page={page} rowsPerPage={rowsPerPage} numSelected={selected.length} selectedIdGuests={selected} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750}}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
            <GuestTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={allGuestsNoLimit.length}
            />
              {guestInfos.length > 0 ? (<TableBody>
                {/* const dataFiltered = filterData(searchQuery, data); */}

                {filterDataBySearchInput(searchQuery, (!searchQuery ? guestInfos : allGuestInfos).slice().sort(getComparator(order, orderBy))).map((oneRowGuest, index) => {
                  const isItemSelected = isSelected(oneRowGuest.guestId);
                  return (
                    <TableRow
                      key={index}
                      hover
                      onClick={(event) => handleClickRowCheckBox(event, oneRowGuest.guestId)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell  padding="none">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': oneRowGuest.guestId,
                          }}
                        />
                      </TableCell>
                      
                      {labelGuestTableHead.map((key, index2) => {
                        return (
                          <TableCell
                            key={index2}
                            padding="none"
                            
                          // align="right"
                          >
                            {key.type === "select" && key.id === "groupe" &&
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                  <InputLabel id="groupe">Selectionnez</InputLabel>
                                  <Select
                                    labelId="groupe"
                                    id="groupe"
                                    value={oneRowGuest[key.id]==null? "" : oneRowGuest[key.id]}
                                    onChange={(event) => handleOnChangeSelectGroup(event, oneRowGuest.guestId, key.id)}
                                    label="Groupe"
                                  >
                                    <MenuItem value={"Commun"}>Commun</MenuItem>
                                    <MenuItem value={capitalizeFirstLetter(organizers.currentOrganizerInfos.firstname)}>{capitalizeFirstLetter(organizers.currentOrganizerInfos.firstname)}</MenuItem>
                                    <MenuItem value={capitalizeFirstLetter(organizers.currentOrganizerInfos.firstnamePartner)}>{capitalizeFirstLetter(organizers.currentOrganizerInfos.firstnamePartner)}</MenuItem>
                                  </Select>
                            </FormControl>}
                            {key.type === "select" && key.id === "type" &&
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                              <InputLabel id="Type">Selectionnez</InputLabel>
                              <Select
                                labelId="Type"
                                id="demo-simple-select-standard"
                                value={oneRowGuest[key.id]==null? "" : oneRowGuest[key.id]}
                                onChange={(event) => handleOnChangeSelectGroup(event, oneRowGuest.guestId, key.id)}
                                label="Type"
                              >
                                <MenuItem value={"Amis"}>Amis</MenuItem>
                                <MenuItem value={"Famille"}>Famille</MenuItem>
                              </Select>
                            </FormControl>
                            }
                            {key.type !== "select" ? (key.id === "lastLogin" && oneRowGuest["lastLogin"] !== null ? moment(new Date(parseInt(oneRowGuest[key.id]))).format('LL') : (key.type === "boolean" ? (oneRowGuest[key.id] === 0 ? "Non" : "Oui") : oneRowGuest[key.id])): ""}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>) : <TableBody><TableRow><TableCell><Box sx={{ padding: '2.5rem', width: '16rem', fontSize: '1.1rem'}}> Ajoutez vos invités ! </Box></TableCell></TableRow></TableBody>}
            </Table>
          </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={countAllGuests}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper> 
      }
      
    </Box>
  );
};
export default GuestTable;
